<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="middlewares" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <label class="uk-form-label">Nazwa</label>
                <div class="uk-margin">
                    <input class="uk-input" data-vv-as="nazwa" name="name" placeholder="" type="text"
                           v-model="middleware.name"
                           v-validate="'required|max:255'">
                    <span class="validation-error">{{ errors.first('name') }}</span>
                </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="submit" class="uk-button wpip-button" type="button" :disabled="modal.blocked">{{modal.button}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from "../mixin";
    import UIkit from "uikit";
    import 'vue-datetime/dist/vue-datetime.css'
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";

    export default {
        name: 'middlewareModal',
        data() {
            return {
                filterValue: "",
                modalDom: null,
                parameter: null,
                key: 0,
                types: [],
                type: 'datetime',
                buildingIndex: 0,
                middleware: {
                },
                modal:
                    {
                        title: "Dodaj middleware",
                        button: "Dodaj",
                        blocked: false
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (middleware) {
                let vm = this;
                this.middleware = middleware;
                if(!middleware.id){
                    vm.modal =
                        {
                            title: "Dodaj middleware",
                            button: "Dodaj",
                            blocked: false
                        }
                } else {
                    vm.middleware.id = middleware.id;
                    vm.middleware.name = middleware.name;
                    this.modal =
                        {
                            title: "Zmień middleware",
                            button: "Zmień",
                            blocked: false
                        }
                }

                this.modalDom.show();
            },
            submit: function(){
                let postData = {
                    name: this.middleware.name,
                };

                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        this.modal.blocked = true;
                        if (!this.middleware.id) {
                            postData.building = '/api/buildings/'+this.$route.params.buildingId;
                            axiosInstance
                                .post(apiConfig.middlewaresApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        } else {
                            axiosInstance
                                .patch(apiConfig.middlewaresApiUrl + '/' + vm.middleware.id, JSON.stringify(postData), {
                                    headers: this.$data.headersLD
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        }
                    }
                });
            }
        },
        mounted() {
            if(this.modalDom==null) {
                this.modalDom = UIkit.modal('#middlewares');
            }
        }

    }
</script>
