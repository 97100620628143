<!--suppress HtmlFormInputWithoutLabel -->
<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-improvement-add" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close/>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <legend class="uk-legend">Informacje podstawowe</legend>

                <div class="uk-margin">
                    <label class="uk-form-label">Nazwa</label>
                    <div class="uk-form-controls">
                        <input
                                class="uk-input"
                                data-vv-as="nazwa"
                                name="name"
                                type="text"
                                v-model="improvement.name"
                                v-validate="'required'"
                        >
                        <span class="validation-error">{{ errors.first('name') }}</span>
                    </div>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">Jednostka organizacyjna zgłaszająca pomysł</label>
                    <div class="uk-form-controls">
                        <v-select :filterable="false" :options="units" @search="search"
                                  label="@id" :class="'wpip-select'" v-model="improvement.unit"  data-vv-as="jednostka organizacyjna zgłaszająca pomysł" name="unit"
                                  v-validate="'required'">

                            <template slot="option" slot-scope="option">
                                <div class="d-center">
                                    {{ option.name }}
                                </div>
                            </template>

                            <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                    {{ option.name }}
                                </div>
                            </template>

                            <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching">
                                    Nie znaleziono <em>{{ search }}</em>.
                                </template>
                                <em style="opacity: 0.5;" v-else>Wyszukaj jednostkę organizacyjną</em>
                            </template>
                        </v-select>
                        <span class="validation-error">{{ errors.first('unit') }}</span>
                    </div>
                </div>
                <div class="uk-margin">
                    <label class="uk-form-label">Obszar usprawnienia</label>
                    <div class="uk-form-controls">
                        <select class="uk-select" id="area" v-model="improvement.iasPhase">
                            <option hidden/>
                            <option v-bind:key="'phase_'+iasPhase.id" :value="iasPhase['@id']" v-for="iasPhase in iasPhases">{{iasPhase.name}}</option>
                        </select>
                        <span class="validation-error">{{ errors.first('area') }}</span>
                    </div>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">Branża</label>
                    <div class="uk-form-controls">
                        <select class="uk-select" data-vv-as="zakres usprawnienia" name="scope"
                                v-model="improvement.industry"
                                v-validate="'required'">
                            <option hidden/>
                            <option v-bind:key="'industry_'+industry.id" :value="industry['@id']" v-for="industry in industries">{{industry.name}}</option>
                        </select>
                        <span class="validation-error">{{ errors.first('scope') }}</span>
                    </div>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">Identyfikacja i opis problemu</label>
                    <div class="uk-form-controls">
                <textarea
                        class="uk-textarea"
                        rows="3"
                        v-model="improvement.problemDescription"
                />
                    </div>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">Opis propozycji rozwiązania problemu i oczekiwane rezultaty</label>
                    <div class="uk-form-controls">
                <textarea
                        class="uk-textarea"
                        rows="5"
                        v-model="improvement.description"
                />
                    </div>
                </div>
              <div class="uk-margin">
                    <label class="uk-form-label">Weryfikacja osiągniętych rezultatów po wdrożeniu</label>
                    <div class="uk-form-controls">
                <textarea
                        class="uk-textarea"
                        rows="5"
                        v-model="improvement.anticipatedResults"
                />
                    </div>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">Dodaj pliki</label>
                    <div class="uk-form-controls">
                        <input accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,application/pdf," id="file" multiple
                               data-vv-as="kaizen" name="files" ref="files" type="file"
                               v-validate="'ext:doc,docx,xls,xlsx,ppt,pptx,pdf'">
                    </div>
                    <span class="validation-error">{{ errors.first('files') }}</span>
                </div>
            </div>

            <div>
                <ul>
                    <li v-bind:key="file.id" v-for="file in improvement.improvementFiles">
                        <span class="open-file" v-on:click="downloadFile(file)">{{ file.filename
                            }}</span> <span @click="removeFile(file.id)" class="remove-file">Usuń</span>
                    </li>
                </ul>
                <file-download ref="download"/>
            </div>

            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="submitImprovement" class="uk-button wpip-button" type="button">{{modal.button}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";
    import FileDownload from "../ui/fileDownload";

    export default {
        name: 'improvementsModal',
        components: {
            fileDownload: FileDownload
        },
        mounted() {

        },
        data() {
            return {
                remove: [],
                units: [],
                iasPhases: [],
                industries: [],
                improvement: {
                    id: null,
                    name: "",
                    description: "",
                    problemDescription: "",
                    anticipatedResults: "",
                    scope: null,
                    area: null,
                    unit: null,
                    improvementFiles: []
                },
                modal:
                    {
                        title: "Zgłoszenie usprawnienia",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        created: function () {
            this.getUnits("");
            let vm = this;
            axiosInstance
                .get(apiConfig.industriesApiUrl,
                    {
                        headers: this.$data.headers
                    }).then(function (result) {

                vm.industries = result.data['member'];
            });
            axiosInstance
                .get(apiConfig.iasPhasesApiUrl,
                    {
                        headers: this.$data.headers
                    }).then(function (result) {

                vm.iasPhases = result.data['member'];
            });
        },
        methods: {
            getUnits: function (e) {
                let vm = this;
                let params = {};
                params['status'] = 1;
                params['name'] = e;
                axiosInstance
                    .get(apiConfig.unitsApiUrl, {
                        params,
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        vm.units = response.data['member'];
                    });
            },
            search: function(e){
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getUnits(e)
                }, 500);
            },
            downloadFile: function (file) {
                this.$refs.download.download('/kaizen/file/' + file.id, file.filename);
            },
            showModal: function (improvement) {
                this.$refs.files.value = null;
                if (improvement == null) {
                    this.improvement.id = null;
                    this.improvement.name = "";
                    this.improvement.description = "";
                    this.improvement.problemDescription = "";
                    this.improvement.area = null;
                    this.improvement.scope = null;
                    this.improvement.industry = null;
                    this.improvement.iasPhase = null;
                    this.improvement.anticipatedResults = "";
                    this.improvement.unit = null;
                    this.improvement.improvementFiles = [];
                    this.modal.button = "Dodaj";
                    this.modal.title = "Zgłoszenie usprawnienia";
                } else {

                    this.improvement.id = improvement.id;
                    this.improvement.name = improvement.name;
                    this.improvement.description = improvement.description;
                    this.improvement.problemDescription = improvement.problemDescription;
                    this.improvement.area = improvement.area;
                    this.improvement.unit = improvement.unit;
                    this.improvement.anticipatedResults = improvement.anticipatedResults;
                    if(improvement.industry!==null) {
                        this.improvement.industry = improvement.industry['@id'];
                    } else {
                        this.improvement.industry = null;
                    }
                    if(improvement.iasPhase!==null) {
                        this.improvement.iasPhase = improvement.iasPhase['@id'];
                    } else {
                        this.improvement.iasPhase = null;
                    }
                    this.improvement.scope = improvement.scope;
                    this.improvement.improvementFiles = improvement.improvementFiles;
                    this.modal.button = "Zmień";
                    this.modal.title = "Edycja zgłoszenia";
                }
                this.$validator.reset();
                UIkit.modal("#wpip-improvement-add").show();
            },
            addFiles: function (file, improvement) {
                let vm = this;
                let formData = new FormData();
                formData.append('file', file);
                formData.append('improvement', improvement);
                axiosInstance.post("/api/uploadFile/keizen", formData, {
                    headers: this.$data.multipartHeaders
                })
                    .then(function () {
                        vm.$emit('refresh');
                    });

            },
            removeFiles: function (file) {
                axiosInstance.delete("/api/removeFile/keizen/" + file, {
                    headers: this.$data.headers
                })
            },
            removeFile: function (e) {
                this.remove.push(e);
                let vm = this;
                this.improvement.improvementFiles.forEach(function (file, id) {
                    if (file.id === e) {
                        vm.improvement.improvementFiles.splice(id, 1);
                    }
                });
            },
            submitImprovement: function () {
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        let author = null;
                        if (localStorage.getItem('person') !== 'null') {
                            author = "/api/people/" + localStorage.getItem('person');
                        }
                      // eslint-disable-next-line no-console
                        let postData = {
                            anticipatedResults: this.improvement.anticipatedResults,
                            name: this.improvement.name,
                            description: this.improvement.description,
                            problemDescription: this.improvement.problemDescription,
                            industry: this.improvement.industry,
                            iasPhase: this.improvement.iasPhase,
                            unit: this.improvement.unit['@id'],
                            author: author,
                            building: "/api/buildings/" + this.$route.params.buildingId
                        };
                        if (author == null || this.improvement.id!==null) {
                            delete postData.author;
                        }
                        if (this.improvement.id == null) {
                            axiosInstance
                                .post(apiConfig.keizensApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(function (response) {
                                    if (response.status === 201) {
                                        UIkit.modal("#wpip-improvement-add").hide();
                                        for (let i = 0; i < vm.$refs.files.files.length; i++) {
                                            vm.addFiles(vm.$refs.files.files[i], response.data.id);
                                        }
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        } else {
                            axiosInstance
                                .patch(apiConfig.keizensApiUrl + "/" + vm.improvement.id, JSON.stringify(postData), {
                                    headers: this.$data.headersLD
                                })
                                .then(function (response) {
                                    if (response.status === 200) {
                                        UIkit.modal("#wpip-improvement-add").hide();
                                        for (let i = 0; i < vm.$refs.files.files.length; i++) {
                                            vm.addFiles(vm.$refs.files.files[i], response.data.id);
                                        }
                                        for (let i = 0; i < vm.remove.length; i++) {
                                            vm.removeFiles(vm.remove[i], response.data.id);
                                        }
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        }
                    }
                }).catch(() => {
                    return false;
                });
            }
        }

    }
</script>


<style scoped>
    li {
        list-style-type: none;
    }

    .open-file {
        color: #777;
        cursor: pointer;
    }

    .remove-file {
        padding-left: 2em;
        font-size: 80%;
        color: darkred;
        cursor: pointer;
    }
</style>
