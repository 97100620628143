<template>
    <span>
           <a
                   @click.prevent="changeRate(-1)"
                   class="uk-icon-button negative-2"
                   href="#"
                   uk-tooltip="Cel nieakceptowalny"
                   v-bind:class="{selected: datatable.item.realizationLevel===-1}"
                   value="0"
           >
                    <vk-icon icon="minus"></vk-icon>
                </a>
                <a
                        @click.prevent="changeRate(1)"
                        class="uk-icon-button negative-1"
                        href="#"
                        uk-tooltip="Cel akceptowalny"
                        v-bind:class="{selected: datatable.item.realizationLevel===1}"
                        value="1"
                >
                    <vk-icon icon="plus"></vk-icon>
                </a>
                <a
                        @click.prevent="changeRate(2)"
                        class="uk-icon-button positive-2 font-smaller"
                        href="#"
                        uk-tooltip="Cel oczekiwany"
                        v-bind:class="{selected: datatable.item.realizationLevel===2}"
                        value="2"
                >
                    <vk-icon icon="plus"></vk-icon>
                    <vk-icon icon="plus"></vk-icon>
                </a>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'goalsActions',
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            changeRate: function (e) {
                let vm = this;
                if (e === this.datatable.item.realizationLevel) {
                    e = 0;
                }
                this.datatable.item.realizationLevel = e;
                let postData = {
                    realizationLevel: this.datatable.item.realizationLevel
                };
                axiosInstance
                    .patch(apiConfig.goalsApiUrl + "/" + this.datatable.item.id, JSON.stringify(postData), {
                        headers: this.$data.headersLD
                    })
                    .then(async function (response) {
                        if (response.status === 200) {
                            vm.$emit('changed');
                        }
                    }).catch();
            }

        }
    }
</script>
<style scoped>
    .negative-2.selected {
        background-color: rgb(240, 80, 110);
        color: white;
    }

    .negative-1.selected {
        background-color: rgb(240, 140, 61);
        color: white;
    }

    .positive-1.selected {
        background-color: rgb(30, 135, 240);
        color: white;
    }

    .positive-2.selected {
        background-color: rgb(50, 210, 150);
        color: white;
    }
</style>
