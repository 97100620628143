<template>
    <admin>
        <div class="uk-container uk-padding">
            <vk-grid>
                <menuAdmin></menuAdmin>
                <div class="uk-width-expand@m">

                    <buildingForm ref="form" v-on:submit="sendData"></buildingForm>

                </div>
            </vk-grid>
        </div>
    </admin>
</template>

<script>

    import {Grid} from 'vuikit/lib/grid';
    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import BuildingForm from '../../components/building/buildingForm';
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'buildingEdit',
        components: {
            VkGrid: Grid,
            buildingForm: BuildingForm
        },
        data() {

            return {
                building: null
            }
        },
        created: function () {
            let vm = this;
            axiosInstance.get(apiConfig.buildingsApiUrl + '/' + this.$route.params.buildingId, {
                headers: this.$data.headers
            }).then(function (result) {
                vm.building = result.data;
                vm.$refs.form.loadData(vm.building);
            });
        },
        mixins: [mixin],
        methods: {
            sendData: function (postData) {
                let vm = this;
                axiosInstance
                    .patch(apiConfig.buildingsApiUrl + '/' + this.$route.params.buildingId, JSON.stringify(postData), {
                        headers: this.$data.headersLD
                    })
                    .then(async function (response) {
                        if (response.status === 200) {
                            let res = await axiosInstance
                                .get(apiConfig.userBuildingsApiUrl+'/'+localStorage.getItem('user'), {
                                    headers: vm.$data.headers,
                                });
                            if (res.status === 200) {
                                localStorage.setItem('buildings', JSON.stringify(res.data));
                            }
                            vm.$router.push('/buildings');
                        }
                    }).catch();
            }
        }
    }
</script>
