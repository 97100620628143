<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-goal-add" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>

                <div class="uk-margin">
                    <label class="uk-form-label">Określ cel operacyjny</label>
                    <div class="uk-form-controls">
                <textarea
                        class="uk-textarea"
                        rows="5"
                        v-model="goal.name"/>
                    </div>
                </div>


                <div class="uk-margin">
                    <label class="uk-form-label">Opisz mierniki realizacji</label>
                    <div class="uk-form-controls">
                <textarea
                        class="uk-textarea"
                        rows="5"
                        v-model="goal.kpis"/>
                    </div>
                </div>

                <div class="uk-grid-small uk-margin" uk-grid>
                    <div class="uk-width-1-2@s">
                        <label class="uk-form-label">Benefit - cel oczekiwany</label>
                        <div class="uk-form-controls">
                            <select class="uk-select" data-vv-as="benefit - cel oczekiwany" name="benefit"
                                    v-model="goal.benefitPerfect">
                                <option hidden></option>
                                <option
                                        :key="'r'+benefit.id"
                                        :value="benefit['@id']"
                                        v-for="benefit in benefits"
                                >{{ benefit.name }}
                                </option>
                            </select>
                            <span class="validation-error">{{ errors.first('responsible') }}</span>
                        </div>
                    </div>
                    <div class="uk-width-1-2@s">
                        <label class="uk-form-label">Benefit - cel akceptowalny</label>
                        <div class="uk-form-controls">
                            <select class="uk-select" data-vv-as="benefit - cel akceptowalny" name="benefit"
                                    v-model="goal.benefitGood">
                                <option hidden></option>
                                <option
                                        :key="'r'+benefit.id"
                                        :value="benefit['@id']"
                                        v-for="benefit in benefits"
                                >{{ benefit.name }}
                                </option>
                            </select>
                            <span class="validation-error">{{ errors.first('responsible') }}</span>
                        </div>
                    </div>
                </div>


                <div class="uk-grid-small uk-margin" uk-grid>
                    <div class="uk-width-1-2@s">
                        <label class="uk-form-label">Osoba odpowiedzialna</label>
                        <div class="uk-form-controls">
                            <v-select :filterable="false" :options="responsible" @search="search"
                                      label="@id" :class="'wpip-select'" v-model="goal.responsible">

                                <template slot="option" slot-scope="option">
                                    <div class="d-center">
                                        {{ option.firstName }} {{ option.lastName }}
                                    </div>
                                </template>

                                <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">
                                        {{ option.firstName }} {{ option.lastName }}
                                    </div>
                                </template>

                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        Nie znaleziono <em>{{ search }}</em>.
                                    </template>
                                    <em style="opacity: 0.5;" v-else>Wyszukaj osobę</em>
                                </template>
                            </v-select>
                            <span class="validation-error">{{ errors.first('responsible') }}</span>
                        </div>
                    </div>
                    <div class="uk-width-1-2@s">
                        <label class="uk-form-label">Termin realizacji</label>
                        <div class="uk-inline uk-width-1-1">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                            <datepicker :disabledDates="disabledDates"
                                    :format="'dd.MM.yyyy'" :input-class="'uk-input'" :language="pl"
                                        data-vv-as="Termin realizacji"
                                        name="dueDate" type="date" v-model="goal.realization"
                                        v-validate="'required'"/>
                            <span class="validation-error">{{ errors.first('dueDate') }}</span>
                        </div>
                    </div>
                </div>

            </div>


            <div class="uk-modal-footer uk-text-right">
                <button @click="cancel" class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="submitGoal" class="uk-button wpip-button" type="button">{{modal.button}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";
    import {pl} from "vuejs-datepicker/src/locale";
    import Datepicker from "vuejs-datepicker";

    export default {
        name: 'operationalModal',
        data() {
            return {
                pl: pl,
                filterValue: "",
                responsible: [],
                benefits: [],
                disabledDates: {
                    to: null
                },
                goal: {
                    id: null,
                    name: "",
                    kpis: "",
                    goalBenefits: [],
                    benefitGood: "",
                    beneftPerfect: "",
                    responsible: null,
                },
                modal:
                    {
                        title: "Dodaj nowy goal",
                        button: "Dodaj"
                    },

            }
        },
        components: {
            datepicker: Datepicker,
        },
        mixins: [mixin],
        methods: {
            fullName: function (person) {
                return person.firstName + " " + person.lastName;
            },
            async getPeople(e) {
                let vm = this;
                let params = {};
                params['firstName'] = e.split(' ');
                params['lastName'] = e.split(' ');
                let res = await axiosInstance
                    .get(apiConfig.peopleApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.responsible = res.data['member'];
                return vm.data;
            },
            search: function(e){
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getPeople(e)
                }, 500);
            },
            showModal: function (goal) {
                let vm = this;
                this.disabledDates.to = new Date();
                this.disabledDates.to.setDate(this.disabledDates.to.getDate() - 1);

                this.disabledDates.from = new Date(this.$attrs['max-date']);
                this.disabledDates.from.setDate(this.disabledDates.from.getDate() + 1);

                if (goal == null) {
                    this.goal.id = null;
                    this.goal.name = "";
                    this.goal.kpis = "";
                    this.goalBenefits = [];
                    this.goal.benefitPerfect = null;
                    this.goal.benefitGood = null;
                    this.goal.person = null;
                    this.goal.realization = null;
                    this.goal.responsible = null;

                    this.modal.button = "Dodaj";
                    this.modal.title = "Dodaj nowy cel";
                } else {

                    this.goalBenefits = [];
                    this.goal.benefitPerfect = null;
                    this.goal.benefitGood = null;
                    this.goal.id = goal.id;
                    this.goal.name = goal.name;
                    this.goal.kpis = goal.kpis;
                    this.goalBenefits = goal.goalBenefits;
                    this.goal.responsible = goal.person;
                    this.goal.realization = goal.realization;

                    this.goalBenefits.forEach(function (goalBenefit) {
                        if(goalBenefit.realizationLevel===1){
                            vm.goal.benefitGood = goalBenefit.benefit;
                        }
                        if(goalBenefit.realizationLevel===2){
                            vm.goal.benefitPerfect = goalBenefit.benefit;
                        }
                    });

                    this.modal.button = "Zmień";
                    this.modal.title = "Zmień cel";
                }
                this.$validator.reset();

                UIkit.modal("#wpip-goal-add").show();
            },
            submitGoal: async function () {
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        let postData = {
                            name: vm.goal.name,
                            kpis: vm.goal.kpis,
                            person: vm.goal.responsible['@id'],
                            realization: vm.goal.realization,
                            parent: '/api/goals/' + vm.$route.params.detailedId,
                            type: true
                            //bonus: this.goal.bonus
                        };
                        if (vm.goal.id == null) {
                            axiosInstance
                                .post(apiConfig.goalsApiUrl, JSON.stringify(postData), {
                                    headers: vm.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        UIkit.modal("#wpip-goal-add").hide();
                                        vm.submitBenefits(vm, response.data['@id']);
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        } else {
                            axiosInstance
                                .patch(apiConfig.goalsApiUrl + "/" + vm.goal.id, JSON.stringify(postData), {
                                    headers: vm.$data.headersLD
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        UIkit.modal("#wpip-goal-add").hide();
                                        vm.submitBenefits(vm, response.data['@id']);
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        }
                    }
                }).catch(() => {
                    return false;
                });
            },
            cancel: function () {
                this.$emit('refresh');
            },
            submitBenefits: function (vm, goalId) {
                if (vm.goalBenefits.length > 0) {
                    vm.goalBenefits.forEach(function (goalBenefit) {
                        axiosInstance
                            .delete(apiConfig.goalBenefitsApiUrl + "/" + goalBenefit.id, {
                                headers: vm.$data.headers
                            })
                    })
                }

                if (vm.goal.benefitGood != null) {
                    let postData = {
                        realizationLevel: 1,
                        benefit: vm.goal.benefitGood,
                        goal: goalId
                    }
                    axiosInstance
                        .post(apiConfig.goalBenefitsApiUrl, JSON.stringify(postData), {
                            headers: vm.$data.headers
                        })
                        .then(async function () {
                        }).catch();
                }
                if (vm.goal.benefitPerfect != null) {
                    let postData = {
                        realizationLevel: 2,
                        benefit: vm.goal.benefitPerfect,
                        goal: goalId
                    }
                    axiosInstance
                        .post(apiConfig.goalBenefitsApiUrl, JSON.stringify(postData), {
                            headers: vm.$data.headers
                        })
                        .then(async function () {
                        }).catch();
                }
            }
        },
        mounted: function () {
            let vm = this;
            this.getPeople("");
            axiosInstance
                .get(apiConfig.benefitsApiUrl, {
                    headers: this.$data.headers
                })
                .then(function (response) {
                    vm.benefits = response.data['member'];
                });
        }

    }
</script>
<style scoped>
    .correlation-header {
        font-size: 1.3em;
    }
</style>
