<template>
    <div class="uk-width-auto">
        <a
                @click.prevent="weightElement(element)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="ustal wagę elementu"
                v-if="analysisType === 1"
        >
            <vk-icon icon="settings"/>
        </a>
        <a
                @click.prevent="editElement(element)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="edytuj opis"
        >
            <vk-icon icon="pencil"/>
        </a>
        <a
                @click.prevent="removeElement(element)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="usuń element"
        >
            <vk-icon icon="trash"/>
        </a>
        <remove ref="remove" v-on:refresh="$emit('refresh', element)"/>
    </div>
</template>

<script>

    import mixin from "../../components/mixin";
    import UIkit from 'uikit';
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";
    import Remove from "../ui/remove";

    export default {
        name: 'swotsActions',
        components: {Remove},
        mixins: [mixin],
        props: {
            block: null,
            element: null,
            analysisType: null
        },
        methods: {
            editElement(element) {
                let vm = this;

                UIkit.modal
                    .prompt("Element analizy SWOT", element.name, {
                        labels: {ok: "Zmień", cancel: "Anluluj"}
                    })
                    .then(function (name) {
                        let postData = {
                            name: name
                        };
                        if (name !== "" && name !== null) {
                            axiosInstance
                                .patch(apiConfig.swotElementsApiUrl + '/' + element.id, postData,
                                    {
                                        headers: vm.$data.headers
                                    }).then(function (result) {

                                element.name = result.data.name;
                            })

                        } else if (name === "") {
                            vm.removeElement(element);
                        }
                    });
            },
            weightElement(element) {
                let vm = this;
                UIkit.modal
                    .prompt(
                        "Zmień wagę elementu <b><i>" + element.name + "</i></b>",
                        element.weight,
                        {
                            labels: {ok: "Zmień", cancel: "Anluluj"}
                        }
                    ).then(function (weight) {
                    weight = weight.replace(',', '.');
                    let postData = {
                        weight: parseFloat(weight)
                    };
                    if (weight !== null) {
                        axiosInstance
                            .patch(apiConfig.swotElementsApiUrl + '/' + element.id, postData,
                                {
                                    headers: vm.$data.headersLD
                                }).then(function (result) {

                            element.weight = result.data.weight;
                            vm.$emit('calculate');
                        })

                    }

                })

            },
            async removeElement(element) {
                await this.$refs.remove.remove(apiConfig.swotElementsApiUrl + '/' + element.id, "element analizy SWOT");
                //this.block.data.splice(this.block.data.indexOf(element), 1);
                //this.$emit('refresh');
            }
        }
    }
</script>

