<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-user-add" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close/>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>

                <div class="uk-margin">
                    <label class="uk-form-label">Nazwa użytkownika</label>
                    <div class="uk-form-controls">
                        <input
                                class="uk-input"
                                data-vv-as="nazwa użytkownika"
                                name="username"
                                type="text"
                                v-model="user.username"
                                v-validate="'required|max:255'"
                        >
                        <span class="validation-error">{{ errors.first('username') }}</span>
                    </div>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">E-mail</label>
                    <div class="uk-form-controls">
                        <input
                                class="uk-input"
                                data-vv-as="email"
                                name="email"
                                type="text"
                                v-model="user.email"
                                v-validate="'required|email'"
                        >
                        <span class="validation-error">{{ errors.first('email') }}</span>
                    </div>
                </div>

                <div class="uk-margin" v-if="user.id===null">
                    <label class="uk-form-label">Hasło</label>
                    <div class="uk-form-controls">
                        <input
                                class="uk-input"
                                data-vv-as="hasło"
                                name="password"
                                type="password"
                                v-model="user.password"
                                v-validate="'required'"
                        >
                        <span class="validation-error">{{ errors.first('password') }}</span>
                    </div>
                </div>
                <div v-if="!user.roles.super">
                <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                    <label class="uk-form-label">Uprawnienia</label>
                    <label><input class="uk-checkbox" name="isIAS" type="checkbox" v-model="user.roles['ias']"
                                  v-on:change="setAllIas" v-bind:disabled="user.roles['management']">
                        Narzędzia IAS</label>
                    <label><input class="uk-checkbox" name="isBMS" type="checkbox" v-model="user.roles['bms']" v-bind:disabled="user.roles['management']">
                        Automatyka</label>
                    <label><input class="uk-checkbox" name="isDevice" type="checkbox" v-model="user.roles['devices']" v-bind:disabled="user.roles['management']">
                        Urządzenia</label>
                    <label><input class="uk-checkbox" name="isExploatation" type="checkbox"
                                  v-model="user.roles['exploatation']" v-bind:disabled="user.roles['management']"> Eksploatacja</label>
                </div>

                <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                    <label class="uk-form-label">Uprawnienia IAS</label>
                    <label><input class="uk-checkbox" name="isSWOT" type="checkbox" v-bind:disabled="user.roles['ias']"
                                  v-model="user.roles['swot']">
                        Analiza SWOT</label>
                    <label><input class="uk-checkbox" name="isQFD" type="checkbox" v-bind:disabled="user.roles['ias']"
                                  v-model="user.roles['qfd']">
                        Analiza QFD </label>
                    <label><input class="uk-checkbox" name="isFMEA" type="checkbox" v-bind:disabled="user.roles['ias']"
                                  v-model="user.roles['fmea']">
                        Narzędzie FMEA </label>
                    <label><input class="uk-checkbox" name="isZPC" type="checkbox" v-bind:disabled="user.roles['ias']"
                                  v-model="user.roles['zpc']"> Zarządzanie przez cele </label>
                    <label><input class="uk-checkbox" name="isKEIZEN" type="checkbox"
                                  v-bind:disabled="user.roles['ias'] || user.roles['keizenteam']" v-model="user.roles['keizen']">
                        Narzędzie Kaizen </label>
                </div>
                  <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                    <label class="uk-form-label">Role</label>
                    <label><input class="uk-checkbox" name="isKEIZENTeam" type="checkbox"
                                  v-model="user.roles['keizenteam']" @change="setKaizen" v-bind:disabled="user.roles['management']">
                      Zespół Kaizen </label>
                    <label><input class="uk-checkbox" name="isManagement" type="checkbox"
                                  v-model="user.roles['management']" @change="setManagement">
                      Zarząd </label>
                  </div>
                </div>
                <div v-else>
                    <label class="uk-alert uk-alert-success">Superuser</label>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">Osoba</label>
                    <v-select :filterable="false" :options="people" @search="search"
                              label="@id" :class="'wpip-select'" v-model="user.person">

                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.firstName }} {{ option.lastName }}
                            </div>
                        </template>

                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.firstName }} {{ option.lastName }}
                            </div>
                        </template>

                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                Nie znaleziono <em>{{ search }}</em>.
                            </template>
                            <em style="opacity: 0.5;" v-else>Wyszukaj osobę</em>
                        </template>
                    </v-select>
                    <span class="validation-error">{{ errors.first('person') }}</span>
                </div>

                <div v-if="user.person['@id']===-1">
                    <legend class="uk-legend">Osoba</legend>
                    <div class="uk-margin">
                        <label class="uk-form-label">Imię</label>
                        <input class="uk-input" data-vv-as="imię" name="first-name" placeholder="Imię"
                               type="text" v-model="person.firstName"
                               v-validate="'required|max:255'">
                        <span class="validation-error">{{ errors.first('first-name') }}</span>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Nazwisko</label>
                        <input class="uk-input" data-vv-as="nazwisko" name="last-name" placeholder="Nazwisko"
                               type="text" v-model="person.lastName"
                               v-validate="'required|max:255'">
                        <span class="validation-error">{{ errors.first('last-name') }}</span>
                    </div>
                    <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                        <label><input class="uk-checkbox" name="is-manager" type="checkbox" v-model="person.isManager">
                            Manager</label>
                    </div>
                    <div class="uk-margin">
                      <label class="uk-form-label">Jednostka organizacyjna</label>

                      <v-select :filterable="false" :options="units" @search="searchUnits" name="unit"
                                label="@id" :class="'wpip-select'" v-model="person.unit" v-validate="'required'" data-vv-as="jednostka organizacyjna">

                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.name }}
                          </div>
                        </template>

                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.name }}
                          </div>
                        </template>

                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            Nie znaleziono <em>{{ search }}</em>.
                          </template>
                          <em style="opacity: 0.5;" v-else>Wyszukaj jednostkę organizacyjną</em>
                        </template>
                      </v-select>
                      <span class="validation-error">{{ errors.first('unit') }}</span>
                    </div>


                </div>


            </div>


            <div class="uk-modal-footer uk-text-right">
                <button @click="cancel" class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="submit" class="uk-button wpip-button" type="button">{{modal.button}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'userModal',
        data() {
            return {
                people: [],
                units: [],
                filter: [],
                searchText: '',
                person: {
                    unit: {}
                },
                filterValue: "",
                user: {
                    id: null,
                    username: "",
                    email: "",
                    password: "",
                    roles: [],
                    person: ""
                },
                modal:
                    {
                        title: "Dodaj nowego użytkownika",
                        button: "Dodaj"
                    }
            }
        },
        mixins: [mixin],
        async mounted() {
            let vm = this;
            var params = {};
            this.getPeople();
            params['status'] = 1;
            axiosInstance
                .get(apiConfig.unitsApiUrl, {
                    params,
                    headers: this.$data.headers
                })
                .then(function (response) {
                    vm.units = response.data['member'];
                });
        },
        methods: {
          searchUnits: function(e){
            let vm = this;
            clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(function () {
              vm.getUnits(e)
            }, 500);
          },
          async getUnits(e) {
            let vm = this;
            let params = {};
            params['name'] = e.split(' ');
            let res = await axiosInstance
                .get(apiConfig.unitsApiUrl,
                    {
                      params,
                      headers: this.$data.headers
                    });
            vm.units = res.data['member'];
            return vm.units;
          },
            fullName: function (person) {
                return person.firstName + " " + person.lastName;
            },
            search: function(e){
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getPeople(e)
                }, 500);
            },
            async getPeople(e="") {
                this.people=[];
                this.people.unshift({
                    firstName: 'Dodaj nową osobę',
                    lastName: "",
                    '@id': -1
                });
                let params = {};
                params['exists[user]'] = false;
                params['fullName'] = e.split(' ');
                let res = await axiosInstance
                    .get(apiConfig.peopleApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                this.people = this.people.concat(res.data["member"]);
                return this.people;
            },
            async get() {
                this.people=[];
                this.people.unshift({
                    firstName: 'Dodaj nową osobę',
                    lastName: "",
                    '@id': -1
                });
                if(this.user.person){
                    let vm = this;
                    axiosInstance
                        .get(this.user.person, {
                            headers: this.$data.headers,
                        })
                        .then(function (response) {
                            vm.people = vm.people.concat(response.data);
                        });
                    }
                this.getAllPeople(apiConfig.peopleApiUrl);
            },
            async getAllPeople(url){
                let vm = this;

                axiosInstance
                    .get(url, {
                        headers: this.$data.headers,
                        params: {
                            'exists[user]': false,
                        },
                    })
                    .then(function (response) {
                        vm.people = vm.people.concat(response.data['member']);
                        if(response.data['view'] && response.data['view']['next']){
                            vm.getAllPeople(response.data['view']['next']);
                        }
                    });

            },
            setRole: function (role) {
                this.user.roles[role.split("_")[1].toLowerCase()] = true;
                if (role === "ROLE_IAS") {
                    this.setAllIas();
                }
            },
            showModal: function (user) {
                if (user == null) {
                    this.person = {
                        unit: {}
                    }
                    this.search('')
                    this.user.id = null;
                    this.user.username = "";
                    this.user.email = "";
                    this.user.password = "";
                    this.user.person = {};
                    this.user.roles = [];
                    this.modal.button = "Dodaj";
                    this.modal.title = "Dodaj nowego użytkownika";
                } else {
                    let vm = this;
                    this.user.id = user.id;
                    this.user.username = user.username;
                    this.user.email = user.email;
                    this.user.person = user.person;
                    if(this.user.person == null){
                        this.user.person = {};
                    }
                    this.user.roles = [];
                    this.modal.button = "Zmień";
                    this.modal.title = "Zmień użytkownika";
                    user.roles.forEach(function (role) {
                        vm.setRole(role);
                    })
                }
                this.$validator.reset();
                UIkit.modal("#wpip-user-add").show();
            },
            setAllIas: function () {
                this.user.roles['swot'] = this.user.roles['ias'];
                this.user.roles['fmea'] = this.user.roles['ias'];
                this.user.roles['qfd'] = this.user.roles['ias'];
                this.user.roles['keizen'] = this.user.roles['ias'] || this.user.roles['keizenteam'];
                this.user.roles['zpc'] = this.user.roles['ias'];
              this.$forceUpdate();
            },
            setKaizen: function(){
              this.user.roles['keizen'] = this.user.roles['ias'] || this.user.roles['keizenteam'];
              this.$forceUpdate();
            },
            setManagement: function(){
              this.user.roles['ias'] = this.user.roles['management'];
              this.user.roles['keizenteam'] = this.user.roles['management'];
              this.user.roles['bms'] = this.user.roles['ias'];
              this.user.roles['devices'] = this.user.roles['ias'];
              this.user.roles['exploatation'] = this.user.roles['ias'];
              this.user.roles['swot'] = this.user.roles['ias'];
              this.user.roles['fmea'] = this.user.roles['ias'];
              this.user.roles['qfd'] = this.user.roles['ias'];
              this.user.roles['keizen'] = this.user.roles['ias'] || this.user.roles['keizenteam'];
              this.user.roles['zpc'] = this.user.roles['ias'];
              this.$forceUpdate();
            },
            submit: async function () {
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        if (this.user.person['@id'] === -1) {
                            let postData = {
                                firstName: this.person.firstName,
                                lastName: this.person.lastName,
                                isManager: this.person.isManager,
                                unit: this.person.unit["@id"],
                                email: this.user.email
                            };
                            axiosInstance
                                .post(apiConfig.peopleApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        vm.user.person = apiConfig.peopleApiUrl + '/' + response.data.id;
                                        vm.submitUser();
                                    }
                                }).catch();
                        } else {
                            this.submitUser();
                        }
                    }
                }).catch(() => {
                    return false;
                });
            },
            submitUser: function () {
                let vm = this;
                let postData = {
                    username: this.user.username,
                    email: this.user.email,
                    roles: {
                        ias: vm.user.roles.ias,
                        bms: vm.user.roles.bms,
                        devices: vm.user.roles.devices,
                        exploatation: vm.user.roles.exploatation,
                        swot: vm.user.roles.swot,
                        qfd: vm.user.roles.qfd,
                        fmea: vm.user.roles.fmea,
                        zpc: vm.user.roles.zpc,
                        keizen: vm.user.roles.keizen,
                        keizenteam: vm.user.roles.keizenteam,
                        management: vm.user.roles.management,
                    },
                    person: this.user.person
                };
                if( typeof postData.person!=='string'){
                  postData.person = postData.person['@id']
                }
                if (this.user.id == null) {
                    postData.password = this.user.password;
                    axiosInstance
                        .post(apiConfig.addUserApiUrl, JSON.stringify(postData), {
                            headers: this.$data.headers
                        })
                        .then(async function (response) {
                            if (response.status === 201) {
                                UIkit.modal("#wpip-user-add").hide();
                                vm.$emit('refresh');
                            }
                        }).catch();
                } else {
                    postData.id = this.user.id;
                    axiosInstance
                        .patch(apiConfig.editUserApiUrl, JSON.stringify(postData), {
                            headers: this.$data.headersLD
                        })
                        .then(async function (response) {
                            if (response.status === 200) {
                                UIkit.modal("#wpip-user-add").hide();
                                vm.$emit('refresh');
                            }
                        }).catch();
                }
            },
            cancel: function () {

            }
        }

    }
</script>
