<template>
    <div class="text-center">
        <input @change="changeStatus" class="uk-checkbox" name="status" type="checkbox" v-model="datatable.item.status">
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import axiosInstance from "../../axiosInstance";
    import UIkit from 'uikit';

    export default {
        name: 'unitsEdit',
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            changeStatus: function () {
                let vm = this;

                let test = true;
                if (!this.datatable.item.status) {
                    if (this.datatable.item.people.length > 0) {
                        UIkit.modal.confirm('Czy na pewno chcesz dezaktywować jednostę z przypisanymi do niej osobami?').catch(function () {
                            vm.datatable.item.status = true;
                            test = false;
                        });
                    }
                }
                if (test) {
                    let postData = {
                        status: this.datatable.item.status
                    };
                    axiosInstance
                        .patch(apiConfig.unitsApiUrl + '/' + this.datatable.item.id, JSON.stringify(postData), {
                            headers: this.$data.headersLD
                        });
                }
            }
        }
    }
</script>
