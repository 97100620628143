<template>
  <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-person-rates" uk-modal>
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close/>
      <div class="uk-modal-body">
        <h3>{{ modal.title }}</h3>
        <vk-grid>
          <div class="uk-width-1-2@m">
            <div>Oceniany: <span class="font-weight-bold">{{ person.firstName }} {{ person.lastName }}</span>
            </div>
            <div v-if="manager != null">Oceniający: {{ manager }}</div>
            <div>Bieżący obiekt: {{ building.name }}</div>
          </div>
          <div class="uk-width-1-2@m">
            <div>Dział: {{ unit }}</div>
            <div>Okres oceny: {{ new Date(dateRange.from).toLocaleDateString() }} -
              {{ new Date(dateRange.to).toLocaleDateString() }}
            </div>
          </div>
        </vk-grid>
        <h4>Cele bez oceny w bieżącym obiekcie</h4>
        <b-table
            :columns="unrated.fields"
            :fields="unrated.fields"
            :items="unrated.data"
            borderedless
            class="uk-table vk-table-justify uk-table-divider"
            hover
            no-sort-reset
            :responsive="true"
            ref="table"
            v-if="unrated.data.length>0"
        >


          <template v-slot:cell(name)="data">
            <span @click="data.toggleDetails">{{ data.detailsShowing ? '-' : '+' }}</span>
            {{ data.item.name }}
          </template>
          <template v-slot:cell(realizationLevel)="data">
            <span v-if="$attrs.mode === 'self'">{{ getLevelLabel(data.item.realizationLevel) }}</span>
            <goals-rate v-else :datatable=data v-on:changed="moveGoal"/>
          </template>
          <template v-slot:cell(benefits)="data">
            {{ getBenefitLabel(data.item.realizationLevel, data.item.goalBenefits) }}
          </template>

          <template v-slot:row-details="row">
            <b-card>
              <table class="uk-table uk-table-hover table-borderless">
                <tr class="table-borderless">
                  <th class="uk-width-1-6">Cel szczegółowy</th>
                  <td>{{ row.item.parent.name }}</td>
                </tr>
                <tr>
                  <th>Cel strategiczny</th>
                  <td>{{ row.item.parent.parent.name }}</td>
                </tr>
                <tr>
                  <th>Mierniki realizacji</th>
                  <td>{{ row.item.kpis }}</td>
                </tr>
              </table>
            </b-card>
          </template>

        </b-table>
        <div uk-alert v-if="unrated.data.length===0">
          Nie znaleziono celów bez oceny w bieżącym obiekcie
        </div>
        <h4>Cele ocenione w bieżącym obiekcie</h4>
        <b-table
            :columns="table.fields"
            :fields="table.fields"
            :items="table.data"
            borderedless
            class="uk-table vk-table-justify uk-table-divider"
            hover
            no-sort-reset
            :responsive="true"
            ref="table"
            v-if="table.data.length>0"

        >

          <template v-slot:cell(name)="data">
            <span @click="data.toggleDetails">{{ data.detailsShowing ? '-' : '+' }}</span>
            {{ data.item.name }}
          </template>

          <template v-slot:cell(realizationLevel)="data">
            <span v-if="$attrs.mode === 'self'">{{ getLevelLabel(data.item.realizationLevel) }}</span>
            <goals-rate v-else :datatable=data v-on:changed="moveGoal"/>
          </template>
          <template v-slot:cell(benefits)="data">
            {{ getBenefitLabel(data.item.realizationLevel, data.item.goalBenefits) }}
          </template>

          <template v-slot:row-details="row">
            <b-card>
              <table class="uk-table uk-table-hover table-borderless">
                <tr class="table-borderless">
                  <th class="uk-width-1-6">Cel szczegółowy</th>
                  <td>{{ row.item.parent.name }}</td>
                </tr>
                <tr>
                  <th>Cel strategiczny</th>
                  <td>{{ row.item.parent.parent.name }}</td>
                </tr>
                <tr>
                  <th>Mierniki realizacji</th>
                  <td>{{ row.item.kpis }}</td>
                </tr>
              </table>
            </b-card>
          </template>

        </b-table>
        <div uk-alert v-if="table.data.length===0">
          Nie znaleziono ocenionych celów w bieżącym obiekcie
        </div>
        <h4>Cele ocenione w pozostałych obiektach</h4>
        <b-table
            :columns="other.fields"
            :fields="other.fields"
            :items="other.data"
            borderedless
            class="uk-table vk-table-justify uk-table-divider"
            hover
            no-sort-reset
            :responsive="true"
            ref="table"

        >

          <template v-slot:cell(name)="data">
            <span @click="data.toggleDetails">{{ data.detailsShowing ? '-' : '+' }}</span>
            {{ data.item.name }}
          </template>

          <template v-slot:cell(realizationLevel)="data">
            {{ getLevelLabel(data.item.realizationLevel) }}
          </template>
          <template v-slot:cell(benefits)="data">
            {{ getBenefitLabel(data.item.realizationLevel, data.item.goalBenefits) }}
          </template>
          <template v-slot:cell(goToBuilding)="data">
            <a
                @click.prevent="changeBuilding(data)"
                class="uk-icon-button"
                href="#"
                uk-tooltip="Przejdź do obiektu"
            >
              <vk-icon class="hover" icon="arrow-right"/>
            </a>
          </template>

          <template v-slot:row-details="row">
            <b-card>
              <table class="uk-table uk-table-hover table-borderless">
                <tr class="table-borderless">
                  <th class="uk-width-1-6">Cel szczegółowy</th>
                  <td>{{ row.item.parent.name }}</td>
                </tr>
                <tr>
                  <th>Cel strategiczny</th>
                  <td>{{ row.item.parent.parent.name }}</td>
                </tr>
              </table>
            </b-card>
          </template>

        </b-table>
        <div class="uk-modal-footer uk-text-right">
          <button @click="cancel" class="uk-button uk-button-default uk-modal-close" type="button">Anuluj
          </button>
          <button @click="downloadPdf" class="uk-button wpip-button" type="button">{{ modal.button }}
          </button>
          <file-download ref="download"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import mixin from "../mixin";
import apiConfig from "../../apiConfig";
import UIkit from "uikit";
import goalRealizationLevels from "../../constants/goalRealizationLevels";
import axiosInstance from "../../axiosInstance";
import {pl} from "vuejs-datepicker/src/locale";
import FileDownload from "../ui/fileDownload";
import GoalsRate from "./goalsRate";

export default {
  name: 'personRatingsModal',
  components: {
    FileDownload,
    GoalsRate
  },
  data() {
    return {
      LEVELS: goalRealizationLevels.LEVELS,
      unit: null,
      manager: null,
      building: {
        name: ""
      },
      other: {
        sortBy: 'name',
        sortDesc: false,
        data: [],
        currentPage: 1,
        totalItems: '',
        fields: [
          {key: 'name', label: 'Cel'},
          {key: 'realization', label: 'Termin realizacji'},
          {key: 'realizationLevel', label: 'Poziom osiągnięcia celu'},
          {key: 'parent.parent.building.name', label: 'Obiekt'},
          {key: 'goToBuilding', label: ''}
        ]
      },
      unrated: {
        sortBy: 'name',
        sortDesc: false,
        data: [],
        currentPage: 1,
        totalItems: '',
        fields: [
          {key: 'name', label: 'Cel'},
          {key: 'realization', label: 'Termin realizacji'},
          {key: 'realizationLevel', label: 'Poziom osiągnięcia celu'},
        ]
      },
      table: {
        sortBy: 'name',
        sortDesc: false,
        data: [],
        currentPage: 1,
        totalItems: '',
        fields: [
          {key: 'name', label: 'Cel'},
          {key: 'realization', label: 'Termin realizacji'},
          {key: 'realizationLevel', label: 'Poziom osiągnięcia celu'},
          {key: 'benefits', label: 'Gratyfikacje (Premia | Bonus)'},
        ]
      },
      pl: pl,
      filterValue: "",
      responsible: [],
      dateRange: {
        from: null,
        to: null
      },
      report: {
        id: null
      },
      person: {
        id: null,
        firstName: "",
        lastName: "",
      },
      modal:
          {
            title: "Dodaj nowy goal",
            button: "Dodaj"
          },

    }
  },
  mixins: [mixin],
  methods: {
    changeBuilding: function (e) {
      localStorage.setItem('currentBuilding', JSON.stringify(e.item.parent.parent.building));
      this.currentBuilding = e.item.parent.parent.building;
      let postData = {
        currentBuilding: e.item.parent.parent.building['@id']
      };
      axiosInstance
          .patch(apiConfig.usersApiUrl + '/' + localStorage.getItem('user'), JSON.stringify(postData), {
            headers: this.$data.headersLD
          });
      this.$router.push({name: "peopleRates", params: {buildingId: e.item.parent.parent.building.id}});
      this.$router.go();
      UIkit.modal("#wpip-person-rates").hide();
    },
    moveGoal: function () {
      this.$emit('refresh')
    },
    showModal: function (report) {
      {
        let vm = this;
        if (this.$attrs.mode === 'self') {
          this.unrated.fields = [
            {key: 'name', label: 'Cel'},
            {key: 'realization', label: 'Termin realizacji'},
          ]
        } else {
          this.unrated.fields = [
            {key: 'name', label: 'Cel'},
            {key: 'realization', label: 'Termin realizacji'},
            {key: 'realizationLevel', label: 'Poziom osiągnięcia celu'},
          ]
        }
        this.person.id = report.person.id;
        this.report.id = report.id
        this.person.firstName = report.person.firstName;
        this.person.lastName = report.person.lastName;
        this.dateRange.from = report.fromDate
        this.dateRange.to = report.toDate
        if (report.person.unit != null) {
          this.unit = report.person.unit.name;
          this.manager = report.person.unit.manager;
        } else {
          this.unit = 'brak';
        }
        this.table.data = [];
        this.other.data = [];
        this.unrated.data = [];
        report.goals.forEach(function (goal) {

          if (goal.realizationLevel !== null && goal.realizationLevel !== 0) {
            if (goal?.parent?.parent?.building.id === parseInt(vm.$route.params.buildingId)) {
              vm.table.data.push(goal);
            } else {
              vm.other.data.push(goal);
            }
          } else if (goal.parent !== null && goal.parent.parent !== null && goal.parent.parent.building !== null && goal.parent.parent.building.id === parseInt(vm.$route.params.buildingId)) {
            vm.unrated.data.push(goal);
          }
        });

        this.modal.button = "Pobierz pdf";
        this.modal.title = "Karta oceny pracownika";
      }

      UIkit.modal("#wpip-person-rates").show();
    },
    cancel: function () {
      UIkit.modal("#wpip-person-rates").hide();
      this.$emit('refresh');
    },
    downloadPdf() {
      this.$refs.download.download("/zpc/" + this.report.id, "Karta Oceny Pracownika.pdf");
    },
    getLevelLabel(level) {
      let label = "";
      this.LEVELS.forEach(function (item) {
        if (item.id === level) {
          label = item.value;
        }
      });
      return label;
    },
    getBenefitLabel(level, benefits) {
      let label = "";
      benefits.forEach(function (item) {
        if (item.realizationLevel === level) {
          let cash;
          let bonus = item.benefit.bonus;
          if (item.benefit.cash === "" || item.benefit.cash == null) {
            cash = "brak";
          } else {
            cash = item.benefit.cash;
          }
          if (item.benefit.bonus === "" || item.benefit.bonus == null) {
            bonus = "brak";
          }
          label = cash + " | " + bonus;
        }
      });
      if (label === "") {
        label = "brak";
      }
      return label;
    }
  },
  mounted: function () {
    let vm = this;
    axiosInstance
        .get(apiConfig.buildingsApiUrl + "/" + vm.$route.params.buildingId, {
          headers: this.$data.headers
        })
        .then(function (response) {
          vm.building = response.data;
        });
  }

}
</script>
