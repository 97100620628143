<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-analysis-add" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>

                <div class="uk-margin">
                    <label class="uk-form-label">Krótki opis analizy</label>
                    <div class="uk-form-controls">
                <textarea
                        class="uk-textarea"
                        data-vv-as="opis"
                        rows="5"
                        name="description"
                        v-model="analysis.description"
                        v-validate="'required'"
                ></textarea>
                    </div>
                    <span class="validation-error">{{ errors.first('description') }}</span>
                </div>
            </div>


            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="submitAnalysis" class="uk-button wpip-button" type="button">{{modal.button}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'qfdAnalysisModal',
        components: {},
        data() {
            return {
                filterValue: "",
                analysis: {
                    description: ""
                },
                modal:
                    {
                        title: "Dodaj nową analizę QFD",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (analysis) {
                if (analysis == null) {
                    this.analysis.id = null;
                    this.analysis.description = "";
                    this.modal.button = "Dodaj";
                    this.modal.title = "Dodaj nową analizę QFD";
                } else {
                    this.parameter.id = analysis.id;
                    this.parameter.description = analysis.description;
                    this.modal.button = "Zmień";
                    this.modal.title = "Zmień anlizę QFD";
                }
                this.$validator.reset();

                UIkit.modal("#wpip-analysis-add").show();
            },
            submitAnalysis: function () {
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        let postData = {
                            description: this.analysis.description,
                            project: "/api/buildings/" + this.$route.params.buildingId
                        };
                        if (this.analysis.id == null) {
                            postData.author = 'api/users/' + localStorage.getItem('user');
                            axiosInstance
                                .post(apiConfig.qfdAnalysesApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(function (response) {
                                    if (response.status === 201) {
                                        UIkit.modal("#wpip-analysis-add").hide();
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        } else {
                            axiosInstance
                                .patch(apiConfig.qfdAnalysesApiUrl + "/" + vm.analysis.id, JSON.stringify(postData), {
                                    headers: this.$data.headersLD
                                })
                                .then(function (response) {
                                    if (response.status === 200) {
                                        UIkit.modal("#wpip-analysis-add").hide();
                                        vm.$emit('refresh');
                                    }
                                }).catch();
                        }
                    }
                }).catch(() => {
                    return false;
                });
            }
        }

    }
</script>
<style scoped>
    .correlation-header {
        font-size: 1.3em;
    }
</style>
