<template>
    <admin>
        <div class="uk-container uk-padding">
            <vk-grid>
                <menuAdmin></menuAdmin>
                <div class="uk-width-expand@m">

                    <contractor-form ref="form" v-on:submit="sendData"></contractor-form>

                </div>
            </vk-grid>
        </div>
    </admin>
</template>

<script>

    import {Grid} from 'vuikit/lib/grid';
    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import ContractorForm from '../../components/contractor/contractorForm';
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'contractorEdit',
        components: {
            VkGrid: Grid,
            contractorForm: ContractorForm
        },
        data() {

            return {
                contractor: null
            }
        },
        created: function () {
            let vm = this;
            axiosInstance.get(apiConfig.contractorsApiUrl + '/' + this.$route.params.contractorId, {
                headers: this.$data.headers
            }).then(function (result) {
                vm.contractor = result.data;
                vm.$refs.form.loadData(vm.contractor);
            });
        },
        mixins: [mixin],
        methods: {
            sendData: function (postData) {
                let vm = this;
                axiosInstance
                    .patch(apiConfig.contractorsApiUrl + '/' + this.$route.params.contractorId, JSON.stringify(postData), {
                        headers: this.$data.headersLD
                    })
                    .then(function (response) {
                        if (response.status === 200) {
                            vm.$router.push('/contractors');
                        }
                    }).catch();
            }
        }
    }
</script>
