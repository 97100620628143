<template>
    <div>
        <vk-grid class="uk-margin-bottom">
            <h3 class="uk-width-expand">Analiza QFD - analiza konkurencji </h3>
            <button
                    @click="back()"
                    class="uk-button wpip-button uk-width-auto"
            >Lista analiz QFD
            </button>
        </vk-grid>
        <vk-card class="uk-margin-bottom">
            <div class="uk-overflow-auto">
                <table class="uk-table  uk-table-divider overflow-y-scroll text-center">
                    <tr>
                        <td>Wymaganie klienta</td>
                        <td class="weight-col">Istotność cechy (waga)</td>
                        <td>Wartość cechy w odniesieniu do konkurencji</td>
                        <td>Znaczenie cech na tle konkurencji</td>
                    </tr>
                    <tr v-bind:key="property.id" v-for="(property, index) in data">
                        <td :uk-tooltip="property.name">{{ limit(property,25) }}</td>
                        <td class="weight-col">{{ property.weight }}</td>
                        <td>
                            <star-rating :maxRate="5" :value="property.competition" v-bind:id="index"
                                        v-on:rated="propertiesChange"/>
                        </td>
                        <td>{{property.weight * property.competition}}</td>
                    </tr>
                </table>
            </div>
        </vk-card>
        <vk-grid class="uk-margin-bottom">
            <h3 class="uk-width-expand">Analiza QFD - wynik analizy </h3>
        </vk-grid>
        <vk-card>
            <div class="uk-overflow-auto">
                <table class="uk-table  uk-table-divider overflow-y-scroll text-center">
                    <tr>
                        <td>Wymaganie klienta</td>
                        <td class="weight-col">Istotność cechy (waga)</td>
                        <td class="competition-col">Wartość cechy w odniesieniu do konkurencji</td>
                        <td>Znaczenie cech na tle konkurencji</td>
                        <td v-bind:key="'p_'+index" v-for="(parameter, index) in selectParameters()"><span :uk-tooltip="parameter.name">{{limit(parameter, 25)}}</span></td>
                    </tr>
                    <tr v-bind:key="property.id" v-for="(property) in data">
                    <td><span  :uk-tooltip="property.name">{{ limit(property,25) }}</span></td>
                        <td class="weight-col">{{ property.weight }}</td>
                        <td class="competition-col">{{ property.competition }}</td>
                        <td>{{ property.weight * property.competition }}</td>
                        <td v-bind:key="parameter.id" v-for="(parameter) in selectParameters()">
                            {{getParameterValue(property,parameter)}}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" class="one-column">Suma iloczynów</td>
                        <td></td>
                        <td v-bind:key="parameter.id" v-for="(parameter) in selectParameters()">{{sums[parameter.id]}}</td>
                    </tr>
                    <tr>
                        <td colspan="3" class="one-column">% całości</td>
                        <td></td>
                        <td v-bind:key="parameter.id" v-for="(parameter) in selectParameters()"><span
                                v-if="sums[0]>0 && sums[parameter.id]>0">{{(sums[parameter.id]*100/sums[0]).toFixed(2)}}%</span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" class="one-column">Suma iloczynów (konkurencja)</td>
                        <td></td>
                        <td v-bind:key="parameter.id" v-for="(parameter)  in selectParameters()">
                            {{competitionSums[parameter.id]}}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" class="one-column">% całości (konkurencja)</td>
                        <td></td>
                        <td v-bind:key="parameter.id" v-for="(parameter) in selectParameters()"><span
                                v-if="competitionSums[0]>0 && competitionSums[parameter.id]>0">{{(competitionSums[parameter.id]*100/competitionSums[0]).toFixed(2)}}%</span>
                        </td>
                    </tr>
                </table>
            </div>
        </vk-card>
        <div class="uk-grid uk-child-width-1-2@m uk-margin-top" uk-grid>
            <div class="uk-text-left">

                <button class="uk-button wpip-button" v-on:click="previousStep">Ranga parametrów</button>
            </div>
            <div class="uk-text-right">
                <button class="uk-button wpip-button" v-on:click="nextStep">Techniczna trudność wykonania</button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../../components/mixin";
    import StarRating from "../ui/starRating";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import charLimiter from "../charLimiter";

    export default {
        name: 'qfdAnalysisCompetitionRating',
        components: {StarRating},
        mixins: [mixin,charLimiter],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                correlationsCount: [],
                sums: [],
                competitionSums: [],
                fields: [
                    {key: 'key', label: "LP", class: "wi"},
                    {key: 'name', label: 'Cecha', sortable: false},
                    {key: 'weight', label: 'Istotność dla klienta', sortable: false},
                    {key: 'parameters', label: 'Liczba powiązanych parametrów', sortable: false},
                    {key: 'actions', label: '', class: 'text-right'},
                ]
            }
        },
        props: {},
        methods: {
            async get() {
                this.data = this.$attrs.properties;
                return this.data;
            },
            selectParameters: function () {
                let vm = this;
                let parameters = [];
                this.$attrs.parameters.forEach(function (parameter) {
                    if (vm.correlationsCount[parameter.id] > 0) {
                        parameters.push(parameter)
                    }
                });
                return parameters;
            },
            refresh: function () {

            },
            getParameterValue(property, parameter) {
                let weight = null;
                property.parameters.forEach(function (propertyParameter) {
                    if (propertyParameter.id === parameter.id) {
                        weight = propertyParameter.weight;
                    }
                });
                return weight;
            },
            calculateSums: function () {
                let vm = this;
                this.sums[0] = 0;
                this.$attrs.parameters.forEach(function (parameter) {
                    vm.sums[parameter.id] = 0;
                    vm.correlationsCount[parameter.id] = 0;
                });
                this.data.forEach(function (property) {
                    property.parameters.forEach(function (parameter) {
                        if (parameter.weight > 0) {
                            vm.correlationsCount[parameter.id]++;
                        }
                        vm.sums[parameter.id] += property.weight * parameter.weight;
                        vm.sums[0] += property.weight * parameter.weight;
                    })
                });
            },
            calculateCompetitionSums: function () {
                let vm = this;
                this.competitionSums[0] = 0;
                this.$attrs.parameters.forEach(function (parameter) {
                    vm.competitionSums[parameter.id] = 0;
                });
                this.data.forEach(function (property) {
                    property.parameters.forEach(function (parameter) {
                        vm.competitionSums[parameter.id] += property.competition * parameter.weight;
                        vm.competitionSums[0] += property.competition * parameter.weight;
                    })
                })
            },
            propertiesChange: function (rating) {
                let vm = this;
                this.data[rating.key].competition = rating.rate;
                let postData = {
                    competition: rating.rate
                };
                axiosInstance
                    .patch(apiConfig.qfdAnalysisPropertiesApiUrl + "/qfdAnalysis=" + this.$route.params.analysisId + ";qfdProperty=" + this.data[rating.key].propertyId, postData,
                        {
                            headers: vm.$data.headersLD
                        }).then(function () {

                });
                this.calculateCompetitionSums();
                this.$emit("propertiesChange", this.data)
            },
            previousStep: function () {
                this.$emit("previousStep");
            },
            nextStep: function () {
                this.$emit("nextStep");
            },
            back: function () {
                this.$router.push({name: 'qfdAnalyses'})
            }

        },
        mounted() {
            this.get();
            this.calculateSums();
            this.calculateCompetitionSums();
        }
    }
</script>

<style scoped>
    .area .weight-col{
        background-color: #eee;
        position: sticky;
        width: 10em;
        left:6.7em;
        border-top: 1px solid rgb(229, 229, 229);
        margin-top: -1px;
    }

    .competition-col{
        position: sticky;
        border-collapse: collapse;
        background-clip: padding-box;
        background-color: white;
        width: 7em;
        left: 12.4em;
        border-top: 1px solid rgb(229, 229, 229);
        margin-top: -1px;
    }

    .area{
        overflow-x: scroll;
    }

    .area td:first-child{
      border-collapse: collapse;
      background-clip: padding-box;
      background-color: white;
      position:sticky;
      left:0;
        width: 15em;
        margin-top: -1px;
        top: auto;
        border-top:1px solid rgb(229, 229, 229);
    }

    .area tr:first-child td:first-child,.area tr:first-child .weight-col,.area tr:first-child .competition-col{
        border-top:none;
    }
</style>
