<template>
    <div id="rental-invoice-modal" class="uk-modal-container" uk-modal="stack: true">
        <div class="uk-modal-dialog uk-modal-body">
            <button class="uk-modal-close-default" type="button" uk-close></button>
          <h3><template v-if="!invoice.id">Dodaj fakturę - {{ contractorName }}</template><template v-else>Zmień fakturę</template> </h3>
            <form class="uk-grid" uk-grid>
                <div class="uk-width-1-2@s">
                    <label class="uk-form-label">Nazwa</label>
                        <input class="uk-input" v-model="invoice.name" type="text"
                               placeholder="proszę podać nazwę należności" v-validate="'required'"
                               data-vv-as="nazwa"
                               name="name">

                    <span class="validation-error">{{ errors.first('name') }}</span>
                </div>
                <div class="uk-width-1-2@s">
                    <label class="uk-form-label">Data faktury</label>
                    <div class="uk-inline uk-width-1-1">
                        <datepicker :format="'dd.MM.yyyy'" :input-class="'uk-input'" :language="pl"
                                    data-vv-as="data faktury"
                                    name="dateReceive" type="date" v-model="invoice.dateReceive"
                                    v-validate="'required'" placeholder="proszę podać datę faktury"></datepicker>
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>

                    </div>
                    <span class="validation-error">{{ errors.first('dateReceive') }}</span>
                </div>
                <div class="uk-width-1-2@s">
                    <label class="uk-form-label">Należność</label>
                    <div class="uk-inline uk-width-1-1">
                        <input class="uk-input" v-model="invoice.value" type="text"
                               placeholder="proszę podać kwotę należności" v-validate="'required'"
                               data-vv-as="należność"
                               name="value">
                        <span class="uk-form-icon uk-form-icon-flip">PLN</span>
                    </div>
                    <span class="validation-error">{{ errors.first('value') }}</span>
                </div>
                <div class="uk-width-1-2@s">
                    <label class="uk-form-label">Data wymagalności</label>
                    <div class="uk-inline uk-width-1-1">
                        <datepicker :format="'dd.MM.yyyy'" :input-class="'uk-input'" :language="pl"
                                    data-vv-as="data wymagalności"
                                    name="paymentDate" type="date" v-model="invoice.datePayment"
                                    v-validate="'required'" placeholder="proszę podać datę wymagalności faktury"></datepicker>
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>

                    </div>
                    <span class="validation-error">{{ errors.first('paymentDate') }}</span>
                </div>
                <div class="uk-width-1-1" v-cloak @drop.prevent="addFile" @dragover.prevent v-if="!invoice.id">
                    <div class="js-upload uk-placeholder uk-text-center">

                        <span uk-icon="icon: cloud-upload"></span>
                        <span class="uk-text-middle">Dodaj plik pdf z fakturą upuszczając go tu lub </span>
                        <div uk-form-custom>
                            <input type="file" @change="inputChanged" ref="file"  accept=".pdf">
                            <span class="uk-link">wybierz dokument z dysku</span>
                        </div>
                        <div v-if="file!==null">
                            {{file.name}}
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-1">
                    <div class="uk-align-right">
                        <button class="uk-button w-button uk-width-auto" @click.prevent="addInvoice"><span v-if="!invoice.id">Dodaj</span><span v-else>Zmień</span></button>
                    </div>
                </div>
            </form>
        </div>
        <file-download ref="download"></file-download>
    </div>
</template>

<script>
    import mixin from "../../components/mixin";
    import charLimiter from "../charLimiter";
    import UIkit from "uikit";
    import {pl} from "vuejs-datepicker/src/locale";
    import Datepicker from "vuejs-datepicker";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import FileDownload from "../ui/fileDownload";

    export default {
        name: "invoiceModal",
        components: {FileDownload, Datepicker},
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                contractorName: '',
                modalDom: null,
                pl: pl,
                file: null,
                invoice: {
                    value: null,
                    name: "",
                    datePayment: null,
                    dateReceive: null,
                    rental: ""
                },
                fields: [
                    {key: 'contractor', label: 'Najemca', sortable: true},
                    {key: 'value', label: 'Należności'},
                    {key: 'date', label: 'Data Wymagalności'},
                    {key: 'status', label: 'Status'},
                    {key: 'actions', label: '', class: 'text-right'},
                ]
            };
        },
        mixins: [mixin, charLimiter],
        created() {
        },
        methods: {
            showModal: function (data) {
                this.contractorName = data.rental.contractor.name
                this.invoice = {
                    rental: data.rental['@id'],
                    contractor: data.rental.contractor['@id'],
                    value: 0,
                }
                this.$validator.reset()
                this.modalDom.show()
            },
            showEditModal: function (data) {
                this.invoice = {...data}
                this.$validator.reset()
                this.modalDom.show()
            },
            inputChanged:function(){
                this.file = this.$refs.file.files[0]
            },
            addFile: function (e) {
                let droppedFiles = e.dataTransfer.files
                if (!droppedFiles) return
                if (droppedFiles.length>1){
                    UIkit.modal.alert("Należy wybrać jeden plik", {
                        labels: {ok: "OK"}, stack: true
                    })
                    return
                }
                if (droppedFiles[0].type!=='application/pdf'){
                    UIkit.modal.alert("Należy wybrać plik pdf", {
                        labels: {ok: "OK"}, stack: true
                    })
                    return
                }
                this.file = droppedFiles[0]
            },
            submitFile: async function (postData) {
                this.invoice.file = this.file
                let formData = new FormData()
                formData.append('file', this.invoice.file)
                await axiosInstance.post("/api/uploadInvoice", formData, {
                    headers: this.$data.multipartHeaders
                })
                    .then(function (response) {
                        if (response.status === 200) {
                            postData.filename = response.data.fileName;
                        }
                    });

            },
            addInvoice: async function () {
                let vm = this
                let postData = {...this.invoice}
                if(this.file) {
                  await this.submitFile(postData)
                }

                postData.value = postData.value.replace(",",".");
                postData.value = parseFloat(postData.value)
                vm.$validator.validate().then((result) => {
                    if (result) {
                      if(!postData.id) {
                        axiosInstance
                            .post(apiConfig.invoicesApiUrl, JSON.stringify(postData), {
                              headers: this.$data.headers
                            })
                            .then(async function (response) {
                              if (response.status === 201) {
                                vm.modalDom.hide()
                                vm.$emit('refresh')
                              }
                            }).catch();
                      }
                      else {
                        axiosInstance
                            .patch(apiConfig.invoicesApiUrl + '/' + postData.id, JSON.stringify(postData), {
                              headers: this.$data.headersLD
                            })
                            .then(async function (response) {
                              if (response.status === 200) {
                                vm.modalDom.hide()
                                vm.$emit('refresh')
                              }
                            }).catch();
                      }
                    }
                })
            }
        },
        mounted() {
            if (this.modalDom == null) {
                let id = '#rental-invoice-modal'
                this.modalDom = UIkit.modal(id)
            }
        }
    };
</script>

<style scoped>
    .uk-accordion li {
        list-style-type: none;
    }

    .uk-accordion-title {
        color: black;
        font-size: 1.3em;
    }

    .uk-accordion {
        padding-left: 0;
    }
</style>
