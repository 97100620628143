<template>
  <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-middlewares-selection" uk-modal>
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" uk-close/>
      <div class="uk-modal-body">
        <h3>{{ modal.title }}</h3>
        <b-row class="mb-1">
          <b-col cols="6">
            <template v-if="filterMiddlewares().length > onPage">
              <b-button variant="outline-wpip" v-if="availablePage > 1" @click="previousPage('available')">Poprzednia strona</b-button>
              <b-button variant="outline-wpip" v-if="availablePage < Math.ceil(filterMiddlewares().length/onPage)" @click="nextPage('available')" class="ml-1">Następna strona</b-button>
            </template>
          </b-col>
          <b-col cols="6" class="text-right" v-if="selectedMiddlewares.length > onPage">
            <b-button variant="outline-wpip" v-if="selectedPage > 1" @click="previousPage('selected')">Poprzednia strona</b-button>
            <b-button variant="outline-wpip" v-if="selectedPage < Math.ceil(selectedMiddlewares.length/onPage)" @click="nextPage('selected')" class="ml-1">Następna strona</b-button>
          </b-col>
        </b-row>
        <vue-list-picker
            :left-items="getPage(filterMiddlewares(), availablePage)"
            :right-items="getPage(selectedMiddlewares, selectedPage)"
            @move-right="moveRight"
            @move-all-right="moveAllRight"
            @move-all-left="moveAllLeft"
            @move-left="moveLeft"
            button-class="uk-button user-selection-buttons"
            content-attr="name"
            content-class="item-selected"
            content-key="@id"
            title-class="section-title"
            title-left="Dostępne middleware"
            title-right="Wybrane middleware"
            :content-substr=128
        />
      </div>


      <div class="uk-modal-footer uk-text-right">
        <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
        <button @click="addMiddleware" class="uk-button wpip-button" type="button">{{ modal.button }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import mixin from "../mixin";
import apiConfig from "../../apiConfig";
import UIkit from "uikit";
import axiosInstance from "../../axiosInstance";

export default {
  name: 'middlewareModal',
  components: {},
  data() {
    return {
      filterValue: "",
      building: null,
      modalDom: null,
      user: null,
      selectedPage: 1,
      availablePage: 1,
      middlewares: [],
      onPage: 10,
      selectedMiddlewares: [],
      modal:
          {
            title: "Przypisanie middleware do rejestru",
            button: "Dodaj"
          },

    }
  },
  mixins: [mixin],
  methods: {

    previousPage(list){
      if(list==='selected'){
        if(this.selectedPage > 1){
          this.selectedPage--
        }
      }
      else if(list==='available'){
        if(this.availablePage > 1){
          this.availablePage--
        }
      }
    },
    nextPage(list){
      if(list==='selected'){
        if(this.selectedPage < Math.ceil(this.selectedMiddlewares.length/this.onPage)){
          this.selectedPage++
        }
      }
      else if(list==='available'){
        if(this.availablePage < Math.ceil(this.filterMiddlewares().length/this.onPage)){
          this.availablePage++
        }
      }
    },
    getPage(list, page){
      return list.slice((page-1)*this.onPage, page*this.onPage)
    },
    moveAllRight(){
      let vm = this
      this.getPage(this.filterMiddlewares(),this.availablePage).forEach(user => {
        vm.moveRight(user)
      })
    },
    moveAllLeft(){
      let vm = this
      this.getPage(this.selectedMiddlewares,this.selectedPage).forEach(user => {
        vm.moveLeft(user)
      })
    },
    moveRight(user){
      this.selectedMiddlewares.push(user)
      if(this.availablePage > Math.ceil(this.filterMiddlewares().length/this.onPage) && this.availablePage>1){
        this.availablePage--
      }
    },
    moveLeft(user){
      this.selectedMiddlewares.forEach((item,key)=>{
        let vm = this
        if(item.id === user.id){
          vm.middlewares.push(user)
          vm.selectedMiddlewares.splice(key, 1)
        }
      })
      if(this.selectedPage > Math.ceil(this.selectedMiddlewares.length/this.onPage) && this.selectedPage>1){
        this.selectedPage--
      }
    },
    showModal: function (registerId) {
      this.user = null
      this.register = registerId
      this.selectedMiddlewares = []
      this.availablePage = 1
      this.selectedPage = 1
      this.getMiddlewares('')
      this.$validator.reset()
      this.modalDom.show()
    },
    getMiddlewares: function (e, page = 1) {
      let vm = this;
      if (page === 1) {
        vm.middlewares = [];
      }
      let params = {};
      params['name'] = e;
      params.page = page;
      axiosInstance
          .get(apiConfig.middlewaresApiUrl, {
            params,
            headers: this.$data.headers
          })
          .then(function (response) {
            vm.middlewares = vm.middlewares.concat(response.data['member']);
            vm.middlewares.forEach(function (middleware) {
              middleware.registers.forEach(function (r) {
                if (r['@id'] === apiConfig.registersApiUrl + '/' + vm.register) {
                  vm.selectedMiddlewares.push(middleware);
                }
              })
            });
            if (response.data['view'] && response.data['view']['next']) {
              vm.getMiddlewares(e, page + 1);
            }
          });
    },
    filterMiddlewares: function () {
      let result = this.middlewares;
      let vm = this;
      vm.selectedMiddlewares.forEach(function (selectedMiddleware) {
        result.forEach(function (middleware, key) {
          if (middleware.id === selectedMiddleware.id) {
            result = result.splice(key, 1);
          }
        })
      });
      return result;

    },
    addMiddleware: function () {
      let vm = this;
      let selected = [];
      vm.selectedMiddlewares.forEach(function (middleware) {
        selected.push(middleware['@id']);
      });
      let postData = {
        middlewares: selected
      };

      axiosInstance
          .patch(apiConfig.registersApiUrl + "/" + this.register, JSON.stringify(postData), {
            headers: vm.$data.headersLD
          })
          .then(function (response) {
            if (response.status === 200) {
              vm.modalDom.hide();
              vm.$emit('refresh');
            }
          });
    }
  },
  mounted() {
    this.getMiddlewares('');
    this.modalDom = UIkit.modal('#wpip-middlewares-selection');
  }

}
</script>
<style>
.section-title {
  color: white !important;
  background-color: #bf0e01 !important;
}

.item-selected.list-picker-selected {
  background-color: #bf4a3f !important;
}

.item-selected.list-picker-selected::selection {
  background-color: #bf4a3f !important;
  color: white !important;
}

.item-selected::selection {
  color: #666 !important;
}

.user-selection-buttons {
  margin-top: 2px !important;
  color: white !important;
}


</style>
