<!--suppress ALL -->
<template>
    <div>
        <vk-grid class="uk-margin-bottom">
            <h3 class="uk-width-expand">Analiza QFD - ocena trudności wykonania </h3>
            <button
                    @click="back()"
                    class="uk-button wpip-button uk-width-auto"
            >Lista analiz qfd
            </button>
        </vk-grid>
        <vk-card>
            <vk-grid>
                <h4 class="uk-width-auto mb-2">Stopień trudności technicznej</h4>
                <star-rating class="uk-width-expand" :maxRate="10" :value="analysis.technicalDifficulty" v-on:rated="setTechnicalDifficulty"></star-rating>
            </vk-grid>
            <textarea
                    class="uk-textarea"
                    rows="5"
                    name="technicalDifficulty"
                    v-model="analysis.technicalDifficultyDescription"
                    placeholder="Proszę opisać stopień trudności technicznej"
            />
            <vk-grid class="mt-4">
                <h4 class="uk-width-auto mb-2">Stopień trudności organizacyjnej</h4>
                <star-rating class="uk-width-expand" :maxRate="10" :value="analysis.organisationalDifficulty" v-on:rated="setOrganisationalDifficulty">></star-rating>
            </vk-grid>
            <textarea
                    class="uk-textarea"
                    rows="5"
                    name="organisationalDifficulty"
                    v-model="analysis.organisationalDifficultyDescription"
                    placeholder="Proszę opisać stopień trudności organizacyjnej"
            />
            <vk-grid class="mt-4">
                <h4 class="uk-width-auto mb-2">Stopień trudności finansowej</h4>
                <star-rating class="uk-width-expand" :maxRate="10" :value="analysis.financialDifficulty" v-on:rated="setFinancialDifficulty">></star-rating>
            </vk-grid>
            <textarea
                    class="uk-textarea"
                    rows="5"
                    name="financialDifficulty"
                    v-model="analysis.financialDifficultyDescription"
                    placeholder="Proszę opisać stopień trudności finansowej"
            />
        </vk-card>
        <div class="uk-grid uk-child-width-1-2@m mt-4" uk-grid>
            <div class="uk-text-left">

                <button class="uk-button wpip-button" v-on:click="previousStep">Ocena konkurencyjności</button>
            </div>
            <div class="uk-text-right">
                <button class="uk-button wpip-button" v-on:click="save">Zapisz</button>
                <button class="uk-button wpip-button download" v-on:click="download">Pobierz raport</button>
                <file-download ref="download"/>
            </div>
        </div>
    </div>

</template>

<script>

    import mixin from "../../components/mixin";
    import StarRating from "../ui/starRating";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import FileDownload from "../ui/fileDownload"

    export default {
        name: 'qfdAnalysistechnicalDifficulty',
        components: {StarRating, FileDownload},
        mixins: [mixin],
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                analysis: {
                    technicalDifficultyDescription: '',
                    organisationalDifficultyDescription: '',
                    financialDifficultyDescription: '',
                },
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'key', label: "LP", class: "wi"},
                    {key: 'name', label: 'Cecha', sortable: false},
                    {key: 'weight', label: 'Istotność dla klienta', sortable: false},
                    {key: 'parameters', label: 'Liczba powiązanych parametrów', sortable: false},
                    {key: 'actions', label: '', class: 'text-right'},
                ]
            }
        },
        props: {},
        methods: {
            async get() {
                let vm = this;
                let res = await axiosInstance
                    .get(apiConfig.qfdAnalysesApiUrl + "/" + this.$route.params.analysisId,
                        {
                            headers: this.$data.headers
                        });
                vm.analysis = res.data;
                vm.totalItems = res.data['totalItems'];
            },
            setTechnicalDifficulty: function(e){
                this.analysis.technicalDifficulty = e.rate;
            },
            setOrganisationalDifficulty: function(e){
                this.analysis.organisationalDifficulty = e.rate;
            },
            setFinancialDifficulty: function(e){
                this.analysis.financialDifficulty = e.rate;
            },
            refresh: function(){

            },
            save: function(){
                let vm = this;
                let postData = this.analysis;
                axiosInstance
                    .patch(apiConfig.qfdAnalysesApiUrl + '/' + this.$route.params.analysisId, JSON.stringify(postData), {
                        headers: this.$data.headersLD
                    })
                    .then(function (response) {
                        if (response.status === 200) {
                            vm.$router.push({name: 'qfdAnalyses'});
                        }
                    }).catch();
            },
            download: function(){
                let vm = this;
                let postData = this.analysis;
                axiosInstance
                    .patch(apiConfig.qfdAnalysesApiUrl + '/' + this.$route.params.analysisId, JSON.stringify(postData), {
                        headers: this.$data.headersLD
                    })
                    .then(function (response) {
                        if (response.status === 200) {
                            vm.$refs.download.download("/qfds/" + vm.$route.params.analysisId, "Analiza QFD.pdf");
                        }
                    }).catch();
            },
            previousStep: function(){
                this.$emit('previousStep');
            },
            back: function(){
                this.$router.push({name: 'qfdAnalyses'})
            }

        },
        mounted() {
            this.get();
        }
    }
</script>
<style scoped>
    .badge{
        background-color: rgb(191, 14, 1);
        color:white;
    }

    .wpip-button.download{
        margin-left:3px;
    }
</style>
