<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-fmea-add-element" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <form class="uk-form-stacked">
                    <legend class="uk-legend">Informacje podstawowe</legend>
                    <div class="uk-margin">
                        <label class="uk-form-label">Faza w cyklu życia IAS</label>
                        <div class="uk-form-controls">
                            <select class="uk-select" data-vv-as="faza w cyklu życia IAS" name="phase"
                                    v-model="fmeaElementForm.iasPhase"
                                    v-validate="'required'">
                                <option hidden></option>
                                <option
                                        :key="'p'+phase.id"
                                        :value="phase.id"
                                        v-for="phase in iasPhase"
                                >{{ phase.id }}. {{ phase.name }}
                                </option>
                            </select>
                            <span class="validation-error">{{ errors.first('phase') }}</span>
                        </div>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label">Obiekt / część obiektu - funkcja obiektu</label>
                        <div class="uk-form-controls">
                            <select class="uk-select" data-vv-as="obiekt" name="object" v-model="fmeaElementForm.object"
                                    v-validate="'required'">
                                <option hidden></option>
                                <option
                                        :key="'s'+subphase.id"
                                        :value="subphase.id"
                                        v-for="subphase in iasSubphase"
                                >{{ subphase.name }}
                                </option>
                            </select>
                            <span class="validation-error">{{ errors.first('object') }}</span>
                        </div>
                    </div>
                    <legend class="uk-legend">Potencjalna wada</legend>
                    <label class="uk-form-label uk-margin">Opis potencjalnej wady</label>
                    <div class="uk-form-controls">
                        <textarea class="uk-textarea" rows="3" v-model="fmeaElementForm.failureDescription"></textarea>
                    </div>

                    <label class="uk-form-label uk-margin">Przyczyny potencjalnej wady</label>
                    <div class="uk-form-controls">
                        <textarea class="uk-textarea" rows="3" v-model="fmeaElementForm.failureCause"></textarea>
                    </div>

                    <label class="uk-form-label uk-margin">Skutki potencjalnej wady</label>
                    <div class="uk-form-controls">
                        <textarea class="uk-textarea" rows="3" v-model="fmeaElementForm.failureEffect"></textarea>
                    </div>

                    <label class="uk-form-label uk-margin">Aktualny sposób kontroli</label>
                    <div class="uk-form-controls">
                        <textarea class="uk-textarea" rows="3" v-model="fmeaElementForm.failureWayOfControl"></textarea>
                    </div>

                    <legend class="uk-legend uk-margin">Ocena potencjalnej wady</legend>
                    <div class="uk-grid uk-margin uk-child-width-1-3@s" uk-grid>
                        <div class>
                            <label class="uk-form-label">
                                Znaczenie wady dla klienta
                                <span
                                        class="uk-margin-small-left uk-badge wpip-badge"
                                >{{ fmeaElementForm.clientValue }}</span>
                            </label>
                            <input
                                    class="uk-range"
                                    data-vv-as="znaczenie wady dla klienta"
                                    max="10"
                                    min="0"
                                    name="clientValue"
                                    step="1"
                                    type="range" v-model="fmeaElementForm.clientValue"
                                    v-validate="'required|min_value:1'"
                                    value="2"
                            />
                            <span class="validation-error">{{ errors.first('clientValue') }}</span>
                            <small>Jak istotne znaczenie dla klienta ma potencjalna wada</small>
                        </div>
                        <div class>
                            <label class="uk-form-label">
                                Ryzyko wystąpienia wady
                                <span
                                        class="uk-margin-small-left uk-badge wpip-badge"
                                >{{ fmeaElementForm.risk }}</span>
                            </label>
                            <input
                                    class="uk-range"
                                    data-vv-as="ryzyko wystąpienia wady"
                                    max="10"
                                    min="0"
                                    name="risk"
                                    step="1"
                                    type="range" v-model="fmeaElementForm.risk" v-validate="'required|min_value:1'"
                                    value="2"
                            />
                            <span class="validation-error">{{ errors.first('risk') }}</span>
                            <small>Jaka jest potencjalna częstotliwość wystąpienia wady</small>
                        </div>
                        <div class>
                            <label class="uk-form-label">
                                Wykrywalność wady
                                <span
                                        class="uk-margin-small-left uk-badge wpip-badge"
                                >{{ fmeaElementForm.detection }}</span>
                            </label>
                            <input
                                    class="uk-range"
                                    data-vv-as="wykrywalność wady"
                                    max="10"
                                    min="0"
                                    name="detection"
                                    step="1"
                                    type="range" v-model="fmeaElementForm.detection" v-validate="'required|min_value:1'"
                                    value="2"
                            />
                            <span class="validation-error">{{ errors.first('detection') }}</span>
                            <small>Jakie jest prawdopodobieństwo że potencjalna wada nie zostanie wykryta</small>
                        </div>
                    </div>

                    <div class="uk-margin uk-text-large">
                        Liczba Priorytetowa Ryzyka (LPR) =
                        <span
                                class="uk-label wpip-label uk-text-large"
                        >{{ calcLPR() }}</span>
                    </div>

                    <legend class="uk-legend uk-margin">Działania zapobiegawcze</legend>
                    <label class="uk-form-label uk-margin">Zalecane działania zapobiegawcze</label>
                    <div class="uk-form-controls">
                        <textarea class="uk-textarea" rows="3" v-model="fmeaElementForm.suggestedActions"></textarea>
                    </div>
                    <div class="uk-grid-small uk-margin" uk-grid>
                        <div class="uk-width-1-2@s">
                            <label class="uk-form-label">Osoba odpowiedzialna</label>
                            <div class="uk-form-controls">
                                <v-select :filterable="false" :options="responsible" @search="search"
                                          label="@id" :class="'wpip-select'" v-model="fmeaElementForm.person"
                                          data-vv-as="osoba odpowiedzialna"
                                          name="responsible"
                                          v-validate="'required'">

                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ option.firstName }} {{ option.lastName }}
                                        </div>
                                    </template>

                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.firstName }} {{ option.lastName }}
                                        </div>
                                    </template>

                                    <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                            Nie znaleziono <em>{{ search }}</em>.
                                        </template>
                                        <em style="opacity: 0.5;" v-else>Wyszukaj osobę</em>
                                    </template>
                                </v-select>
                                <span class="validation-error">{{ errors.first('responsible') }}</span>
                            </div>
                        </div>
                        <div class="uk-width-1-2@s">
                            <label class="uk-form-label">Termin realizacji</label>
                            <div class="uk-inline uk-width-1-1">
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                <datepicker :format="'dd.MM.yyyy'" :input-class="'uk-input'" :language="pl"
                                            data-vv-as="Termin realizacji"
                                            name="dueDate" type="date" v-model="fmeaElementForm.dueDate"
                                            v-validate="'required'"></datepicker>
                                <span class="validation-error">{{ errors.first('dueDate') }}</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="addFmeaElement" class="uk-button wpip-button" type="button"
                        v-if="fmeaElementForm.id==null">Dodaj
                </button>
                <button @click="editFmeaElement" class="uk-button wpip-button" type="button" v-else>Edytuj</button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";
    import Datepicker from "vuejs-datepicker";
    import {pl} from "vuejs-datepicker/src/locale";


    export default {
        components: {
            Datepicker
        },
        name: 'fmeaElementModal',
        data() {
            return {
                pl: pl,
                iasPhase: [
                    {
                        id: 1,
                        name: "Projektowanie"
                    },
                    {
                        id: 2,
                        name: "Budowa"
                    },
                    {
                        id: 3,
                        name: "Eksploatacja"
                    },
                    {
                        id: 4,
                        name: "Rozwój"
                    }
                ],
                iasSubphase: [
                    {
                        id: 1,
                        name: "Magazyn"
                    },
                    {
                        id: 2,
                        name: "Biuro"
                    },
                    {
                        id: 3,
                        name: "Hala produkcyjna"
                    },
                    {
                        id: 4,
                        name: "Parking"
                    }
                ],
                searchTimeout: null,
                responsible: [],
                fmeaElementForm:
                    {
                        id: 0,
                        clientValue: 0,
                        risk: 0,
                        detection: 0,
                        responsible: "",
                        dueDate: "",
                        suggestedActions: "",
                        failureWayOfControl: "",
                        failureDescription: "",
                        failureCause: "",
                        failureEffect: "",
                        iasPhase: "",
                        object: ""
                    }

            }
        },
        mixins: [mixin],
        methods: {
            fullName: function (person) {
                return person.firstName + " " + person.lastName;
            },
            async get() {
                this.getPeople("");
            },
            search: function(e){
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getPeople(e)
                }, 500);
            },
            async getPeople(e) {
                let vm = this;
                let params = {};
                params['firstName'] = e.split(' ');
                params['lastName'] = e.split(' ');
                let res = await axiosInstance
                    .get(apiConfig.peopleApiUrl,
                        {
                            params,
                            headers: this.$data.headers
                        });
                vm.responsible = res.data['member'];
                return vm.responsible;
            },
            showModal: function (analysis) {
                if (analysis == null) {
                    this.fmeaElementForm =
                        {
                            id: null,
                            clientValue: 0,
                            risk: 0,
                            detection: 0,
                            responsible: "",
                            dueDate: "",
                            suggestedActions: "",
                            failureWayOfControl: "",
                            failureDescription: "",
                            failureCause: "",
                            failureEffect: "",
                            iasPhase: "",
                            object: ""
                        }
                } else {
                    this.fmeaElementForm = analysis;
                    this.fmeaElementForm.responsible = analysis.person;
                }
                this.$validator.reset();
                UIkit.modal("#wpip-fmea-add-element").show();
                this.get();
            },

            calcLPR(element) {
                let f = this.fmeaElementForm;
                if (element) {
                    f = element;
                }

                let lpr = f.clientValue * f.risk * f.detection;

                if (lpr === 0) {
                    return "proszę ocenić wadę";
                } else {
                    return lpr;
                }
            },
            addFmeaElement: function () {
                let vm = this;
                this.$validator.validate().then((result) => {
                    if (result) {
                        let postData = this.fmeaElementForm;
                        postData.clientValue = parseInt(postData.clientValue);
                        postData.risk = parseInt(postData.risk);
                        postData.detection = parseInt(postData.detection);
                        postData.person = "/api/people/" + postData.person['id'];
                        postData.author = "/api/users/" + localStorage.getItem('user');
                        postData.fmeaAnalysis = "/api/fmea_analyses/" + this.$route.params.analysisId;

                        axiosInstance
                            .post(apiConfig.fmeaElementsApiUrl, JSON.stringify(postData), {
                                headers: this.$data.headers
                            })
                            .then(function (response) {
                                if (response.status === 201) {
                                    UIkit.modal("#wpip-fmea-add-element").hide();
                                    vm.$emit('modified');
                                }
                            }).catch();
                    }
                }).catch(() => {
                    return false;
                });
            },
            editFmeaElement: function () {
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        let postData = this.fmeaElementForm;
                        //let id = postData.id;
                        //delete postData.id;
                        postData.clientValue = parseInt(postData.clientValue);
                        postData.risk = parseInt(postData.risk);
                        postData.detection = parseInt(postData.detection);
                        postData.person = "/api/people/" + postData.person['id'];
                        postData.fmeaAnalysis = "/api/fmea_analyses/" + this.$route.params.analysisId;
                        postData.dueDate = new Date(postData.dueDate);
                        axiosInstance
                            .patch(apiConfig.fmeaElementsApiUrl + "/" + postData.id, JSON.stringify(postData), {
                                headers: this.$data.headersLD
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    UIkit.modal("#wpip-fmea-add-element").hide();
                                    vm.$emit('modified');
                                }
                            }).catch();
                    }
                }).catch(() => {
                    return false;
                });
            }
        },
        mounted() {
        }

    }
</script>
<style scoped>
    .uk-close {
        color: #bf0e01 !important;
    }

    .wpip-label {
        border: 1px solid #bf0e01;
        background-color: #fff;
        color: #bf0e01;
    }

    .wpip-badge {

        background-color: #bf0e01;
    }

    .wpip-text-large {
        font-size: 1.3em;
    }

    .wpip-fmea-danger td {
        background-color: #bf0e011e;
        font-weight: bold;
    }

    .wpip-fmea-danger-lpr {
        color: #bf0e01;
        font-size: 1.3em;
    }

    .uk-table-justify td:first-child {
        padding-left: 8px;
    }

    .uk-table-justify td:last-child {
        padding-right: 8px;
    }


</style>
<style>

</style>
