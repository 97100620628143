<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="buildings" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <label class="uk-form-label">Nazwa</label>
                <div class="uk-margin">
                    <input class="uk-input" data-vv-as="nazwa" name="name" placeholder="Nazwa" type="text"
                           v-model="building.name"
                           v-validate="'required|max:255'">
                    <span class="validation-error">{{ errors.first('name') }}</span>
                </div>

                <label class="uk-form-label">Sekwencja</label>
                <div class="uk-margin">
                    <input class="uk-input" data-vv-as="sekwencja" name="sequence" placeholder="" type="text"
                           v-model="building.sequence"
                           v-validate="'numeric'">
                    <span class="validation-error">{{ errors.first('sequence') }}</span>
                </div>
                <label class="uk-form-label">Obraz</label>
                <div class="uk-margin">
                    <img :src="imageSrc" height="100px" v-if="showImage" width="100px">
                    <input @change="changePreview" name="image" accept="image/*" id="file" ref="imageFile" type="file"
                           data-vv-as="obraz budynku" v-validate="'ext:jpg,jpeg,png,bmp,svg'">
                    <span class="validation-error">{{ errors.first('image') }}</span>
                </div>

                <label class="uk-form-label">Opis</label>
                <div class="uk-margin">
                                <textarea class="uk-textarea" name="description" placeholder="Opis"
                                          v-model="building.description" rows="10"></textarea>
                </div>

                <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                    <label><input class="uk-checkbox" name="isIAS" type="checkbox" v-model="building.isIAS">
                        Narzędzia IAS</label>
                    <label><input class="uk-checkbox" name="isBMS" type="checkbox" v-model="building.isBMS">
                        Automatyka</label>
                    <label><input class="uk-checkbox" name="isDevice" type="checkbox" v-model="building.isDevice">
                        Urządzenia</label>
                    <label><input class="uk-checkbox" name="isExploatation" type="checkbox"
                                  v-model="building.isExploatation"> Eksploatacja</label>
                    <label><input class="uk-checkbox" name="isRental" type="checkbox" v-model="building.isRental">
                        Wynajem</label>
                    <label><input class="uk-checkbox" name="isAnalysis" type="checkbox"
                                  v-model="building.isAnalysis"> Analiza</label>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">Kontrahent</label>
                    <v-select :filterable="false" :options="contractors" @search="search" label="@id"
                              :class="'wpip-select'" v-model="building.contractor">

                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.name }}
                            </div>
                        </template>

                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>

                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                Nie znaleziono <em>{{ search }}</em>.
                            </template>
                            <em style="opacity: 0.5;" v-else>Wyszukaj kontrahenta</em>
                        </template>
                    </v-select>
                    <span>{{ errors.first('contractor') }}</span>
                </div>

                <div class="uk-margin">
                    <label>Data rozpoczęcia</label>
                    <datepicker :disabledDates="disabledBeginDates" :format="'dd.MM.yyyy'" :input-class="'uk-input'"
                                :language="pl" data-uk-datepicker="{format:'DD.MM.YYYY'}" data-vv-as="data rozpoczęcia"
                                id="test"
                                name="beginDate" type="text" v-model="building.beginDate"
                                v-on:selected="changeBeginDate">>
                    </datepicker>
                    <span class="validation-error">{{ errors.first('beginDate') }}</span>
                </div>

                <div class="uk-margin">
                    <label>Data zakończenia</label>
                    <datepicker :disabledDates="disabledEndDates" :format="'dd.MM.yyyy'" :input-class="'uk-input'"
                                :language="pl"
                                data-vv-as="data zakończenia" name="endDate"
                                ref="endDate" type="date" v-model="building.endDate"
                                v-on:selected="changeEndDate"></datepicker>
                    <span class="validation-error">{{ errors.first('endDate') }}</span>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                    <button :disabled="modal.blocked" @click="submit" class="uk-button wpip-button" type="button">
                        {{modal.button}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from "../mixin";
    import UIkit from "uikit";
    import 'vue-datetime/dist/vue-datetime.css'
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import Datepicker from "vuejs-datepicker";
    import {pl} from "vuejs-datepicker/src/locale";

    export default {
        name: 'buildingModal',
        components: {

            Datepicker
        },
        data() {
            return {
                pl: pl,
                filterValue: "",
                modalDom: null,
                parameter: null,
                key: 0,
                types: [],
                type: 'datetime',
                imageSrc: '',
                buildingIndex: 0,
                searchTimeout: null,
                contractors: [],
                building: {
                    name: null,
                    contractor: null,
                    description: null,
                    isIAS: false,
                    isBMS: false,
                    isDevice: false,
                    isExploatation: false,
                    isRental: false,
                    isAnalysis: false,
                    beginDate: null,
                    endDate: null,
                    image: null,
                    imageFile: null,
                    sequence: null
                },
                disabledEndDates: {},
                disabledBeginDates: {},
                showImage: null,
                modal:
                    {
                        title: "Dodaj jednostkę organizacyjną",
                        button: "Dodaj",
                        blocked: false
                    },

            }
        },
        mixins: [mixin],
        methods: {
            getContractors: function (e) {
                let vm = this;
                var params = {};
                params['name'] = e;
                axiosInstance
                    .get(apiConfig.contractorsApiUrl, {
                        params,
                        headers: this.$data.headers
                    })
                    .then(function (response) {
                        vm.contractors = response.data['member'];
                    });
            },
            changeBeginDate: function (date) {
                this.$nextTick(() => this.disabledEndDates = {to: date});
                if (date > this.building.endDate) {
                    this.building.endDate = date;
                }

            },
            changeEndDate: function (date) {
                this.$nextTick(() => this.disabledBeginDates = {from: date});
                if (date < this.building.beginDate) {
                    this.building.beginDate = date;
                }
            },
            changePreview: function () {
                let vm = this;
                var reader = new FileReader();

                reader.onload = function (e) {
                    vm.showImage = true;
                    vm.imageSrc = e.target.result;
                };

                reader.readAsDataURL(this.$refs.imageFile.files[0]);
            },
            search: function (e) {
                let vm = this;
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(function () {
                    vm.getContractors(e)
                }, 500);
            },
            loadData: function (building) {
                this.building = building;
                this.disabledEndDates.to = new Date(building.beginDate);
                this.disabledBeginDates.from = new Date(building.endDate);
                this.imageSrc = apiConfig.apiUrl + "/uploads/images/building/" + this.building.image;
                if (this.building.image != null) {
                    this.showImage = true;
                }
            },
            setImage: function (postData) {
                this.building.imageFile = this.$refs.imageFile.files[0];
                let vm = this;
                let formData = new FormData();
              formData.append('image', this.building.imageFile);
              if(this.building.image) {
                formData.append('oldImage', this.building.image);
              }
                axiosInstance.post("/api/uploadFile", formData, {
                    headers: this.$data.multipartHeaders
                })
                    .then(function (response) {
                        if (response.status === 200) {
                            vm.building.image = response.data.fileName;
                            postData.image = vm.building.image;
                            vm.post(postData);
                        }
                    });

            },
            showModal: async function (building) {
                let vm = this;
                this.imageSrc = ""
                this.showImage = false
                vm.$refs.imageFile.value = null
                this.building = building;
                this.loadData(building);
                if (building.id == null) {
                    vm.modal =
                        {
                            title: "Dodaj budynek",
                            button: "Dodaj",
                            blocked: false
                        }
                } else {
                    this.modal =
                        {
                            title: "Zmień budynek",
                            button: "Zmień",
                            blocked: false
                        }
                }

                await this.$validator.reset();
                this.modalDom.show();
            },
            submit: async function () {
                let vm = this;
                if (!vm.building.contractor) {
                    vm.building.contractor = {
                        "@id": null
                    }
                }

                if (this.building.beginDate === "") {
                    this.building.beginDate = null;
                }
                if (this.building.endDate === "") {
                    this.building.endDate = null;
                }
                let postData = {
                    name: vm.building.name,
                    description: vm.building.description,
                    image: vm.building.image,
                    isIAS: vm.building.isIAS,
                    isBMS: vm.building.isBMS,
                    isDevice: vm.building.isDevice,
                    isExploatation: vm.building.isExploatation,
                    isRental: vm.building.isRental,
                    isAnalysis: vm.building.isAnalysis,
                    beginDate: vm.building.beginDate,
                    endDate: vm.building.endDate,
                    contractor: vm.building.contractor['@id'],
                    sequence: parseInt(vm.building.sequence)
                };
                vm.$validator.validate().then(async (result) => {
                    if (result) {
                        if (vm.$refs.imageFile.files[0]) {
                            vm.setImage(postData);
                        } else {
                            vm.post(postData);
                        }
                    }
                });
            },
            post: function (postData) {
                let vm = this;
                this.modal.blocked = true;
                if (!this.building.id) {
                    axiosInstance
                        .post(apiConfig.buildingsApiUrl, JSON.stringify(postData), {
                            headers: this.$data.headersLD
                        })
                        .then(async function (response) {
                            if (response.status === 201) {
                                vm.$emit('refresh');
                                vm.modalDom.hide();
                            }
                            vm.modal.blocked = false;
                        }).catch();
                } else {
                    axiosInstance
                        .patch(apiConfig.buildingsApiUrl + '/' + vm.building.id, JSON.stringify(postData), {
                            headers: this.$data.headersLD
                        })
                        .then(async function (response) {
                            if (response.status === 200) {
                                vm.$emit('refresh');
                                vm.modalDom.hide();
                            }
                            vm.modal.blocked = false;
                        }).catch();
                }
            }
        },
        mounted() {
            this.getContractors();
            if (this.modalDom == null) {
                this.modalDom = UIkit.modal('#buildings');
            }
        }

    }
</script>
