<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="wpip-password-change" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close/>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>


                <div class="uk-margin">
                    <label class="uk-form-label">Hasło</label>
                    <div class="uk-form-controls">
                        <input
                                class="uk-input"
                                data-vv-as="hasło"
                                name="password"
                                type="password"
                                v-model="user.password"
                                v-validate="'required'"
                        >
                        <span class="validation-error">{{ errors.first('password') }}</span>
                    </div>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button @click="cancel" class="uk-button uk-button-default uk-modal-close" type="button">Anuluj
                    </button>
                    <button @click="submit" class="uk-button wpip-button" type="button">{{modal.button}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'passwordModal',
        data() {
            return {
                people: [],
                units: [],
                person: {
                    unit: {}
                },
                filterValue: "",
                user: {
                    id: null,
                    username: "",
                    email: "",
                    password: "",
                    person: null
                },
                modal:
                    {
                        title: "Dodaj nowego użytkownika",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (user) {
                this.user.id = user.id;
                this.user.password = '';
                this.modal.button = "Zmień";
                this.modal.title = "Zmień hasło";
                this.$validator.reset();

                UIkit.modal("#wpip-password-change").show();
            },
            submit: async function () {
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        this.submitPassword()
                    }
                }).catch(() => {
                    return false;
                });
            },
            submitPassword: function () {
                let vm = this;
                let postData = {
                    password: this.user.password,
                    id: this.user.id,
                };

                axiosInstance
                    .patch(apiConfig.changePassApiUrl, JSON.stringify(postData), {
                        headers: this.$data.headersLD
                    })
                    .then(async function (response) {
                        if (response.status === 200) {
                            UIkit.modal("#wpip-password-change").hide();
                            vm.$emit('refresh');
                        }
                    }).catch();
            },
            cancel: function () {
                this.$emit('refresh');
            }
        }

    }
</script>
