<template>
    <div class="uk-flex-top uk-modal-container" container=".admin" id="contractors" uk-modal>
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <div class="uk-margin">
                    <label class="uk-form-label">Nazwa</label>
                    <input class="uk-input" name="name" v-model="contractor.name" type="text" placeholder="Nazwa" data-vv-as="nazwa"
                           v-validate="'required|max:255'">
                    <span class="validation-error">{{ errors.first('name') }}</span>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">Adres e-mail</label>
                    <input class="uk-input" name="email" v-model="contractor.email" type="text" placeholder="E-mail" data-vv-as="e-mail"
                           v-validate="'required|email|max:255'">
                    <span class="validation-error">{{ errors.first('email') }}</span>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">Numer telefonu</label>
                    <input class="uk-input" name="phone" v-model="contractor.phone" type="text" placeholder="Nr telefonu" data-vv-as="numer telefonu"
                           v-validate="'required|phone|max:255'">
                    <span class="validation-error">{{ errors.first('phone') }}</span>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">Adres</label>
                    <input class="uk-input" name="address" v-model="contractor.address" type="text" placeholder="Adres" data-vv-as="adres"
                           v-validate="'required'">
                    <span class="validation-error">{{ errors.first('address') }}</span>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">Miasto</label>
                    <input class="uk-input" name="city" v-model="contractor.city" type="text" placeholder="Miasto" data-vv-as="miasto" v-validate="'max:255'">
                    <span class="validation-error">{{ errors.first('city') }}</span>
                </div>


                <div class="uk-margin">
                    <label class="uk-form-label">Kod</label>
                    <input class="uk-input" name="internalCode" v-model="contractor.internalCode" type="text"
                           placeholder="Kod" v-validate="'required|max:255'" data-vv-as="kod">
                    <span class="validation-error">{{ errors.first('internalCode') }}</span>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label">NIP</label>
                    <input class="uk-input" name="taxNo" v-model="contractor.taxNo" type="text" placeholder="NIP"
                           v-validate="'required|nip'" data-vv-as="NIP">
                    <span class="validation-error">{{ errors.first('taxNo') }}</span>
                </div>
                <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid" v-if="!renterMode">
                    <label><input class="uk-checkbox" type="checkbox" name="status[0]" v-model="contractor.statusBool[0]">
                        Klient</label>
                    <label><input class="uk-checkbox" type="checkbox" name="status[1]" v-model="contractor.statusBool[1]">
                        Partner</label>
                    <label><input class="uk-checkbox" type="checkbox" name="status[2]" v-model="contractor.statusBool[2]">
                        Konsorcjant</label>
                    <label><input class="uk-checkbox" type="checkbox" name="status[3]" v-model="contractor.statusBool[3]">
                        Konkurent</label>
                    <label><input class="uk-checkbox" type="checkbox" name="status[4]" v-model="contractor.statusBool[4]">
                        Podwykonawca</label>
                    <label><input class="uk-checkbox" type="checkbox" name="status[5]" v-model="contractor.statusBool[5]"> Firma
                        projektowa</label>
                    <label><input class="uk-checkbox" type="checkbox" name="status[6]" v-model="contractor.statusBool[6]">
                        Dostawca</label>
                    <label><input class="uk-checkbox" type="checkbox" name="status[7]" v-model="contractor.statusBool[7]">
                        Wykonawca</label>
                    <label><input class="uk-checkbox" type="checkbox" name="renter" v-model="contractor.isRenter">
                        Najemca</label>
                </div>


                <div class="uk-margin">
                    <label class="uk-form-label">Opis</label>
                    <textarea class="uk-input" name="description" v-model="contractor.description"
                              placeholder="Opis"></textarea>
                    <span>{{ errors.first('description') }}</span>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                    <button :disabled="modal.blocked" @click="submit" class="uk-button wpip-button" type="button">
                        {{modal.button}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from "../mixin";
    import UIkit from "uikit";
    import 'vue-datetime/dist/vue-datetime.css'
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";

    export default {
        name: 'contractorModal',
        props: {
            'renterMode': {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                filterValue: "",
                modalDom: null,
                parameter: null,
                key: 0,
                types: [],
                type: 'datetime',
                buildingIndex: 0,
                contractor: {
                    id: null,
                    name: null,
                    email: null,
                    phone: null,
                    address: null,
                    city: null,
                    internalCode: null,
                    taxNo: null,
                    status: 0,
                    description: null,
                    isRenter: null,
                    statusBool: [false, false, false, false, false, false, false, false]},
                modal:
                    {
                        title: "Dodaj jednostkę organizacyjną",
                        button: "Dodaj",
                        blocked: false
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: async function (contractor) {
                let vm = this;
                this.contractor = {...contractor};
                this.contractor.statusBool = [false, false, false, false, false, false, false, false];
                if (contractor.id == null) {
                    this.contractor.status = 0;
                    vm.modal =
                        {
                            title: "Dodaj kontrahenta",
                            button: "Dodaj",
                            blocked: false
                        }
                } else {

                    for (let i = 0; i < 8; i++) {
                        vm.contractor.statusBool[i] = vm.contractor.status % 2;
                        vm.contractor.status = Math.floor(vm.contractor.status / 2)
                    }
                    this.modal =
                        {
                            title: "Zmień kontrahenta",
                            button: "Zmień",
                            blocked: false
                        }
                }

                await this.$validator.reset();

                this.modalDom.show();
            },
            submit: function () {
                if(this.$props.renterMode){
                    this.contractor.isRenter = true;
                }
                let postData = this.contractor;
                let value=1;
                for(var i=0; i<8; i++){
                    if(this.contractor.statusBool[i]){
                        this.contractor.status+=value;
                    }
                    value*=2;
                }
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        this.modal.blocked = true;
                        if (!this.contractor.id) {
                            axiosInstance
                                .post(apiConfig.contractorsApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        } else {
                            axiosInstance
                                .patch(apiConfig.contractorsApiUrl + '/' + vm.contractor.id, JSON.stringify(postData), {
                                    headers: this.$data.headersLD
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        }
                    }
                });
            }
        },
        mounted() {
            if (this.modalDom == null) {
                this.modalDom = UIkit.modal('#contractors');
            }
        }

    }
</script>
