<template>
    <div class="uk-flex-top uk-modal-container uk-modal" container=".admin" id="budget-main-category-modal">
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <div class="uk-form-controls">
                    <label>
                        Nazwa
                    </label>
                        <input
                                type="text"
                                class="uk-input"
                                data-vv-as="nazwa"
                                name="name"
                                placeholder="Proszę podać nazwę kategorii"
                                v-model="category.name"
                                v-validate="'required|max:255'"

                        />
                    <span class="validation-error">{{ errors.first('name') }}</span>
                </div>
                <div class="uk-form-controls">
                    <label>
                        Opis
                    </label>
                        <textarea
                                class="uk-textarea"
                                data-vv-as="opis"
                                name="description"
                                placeholder="Proszę podać opis kategorii"
                                rows="5" v-model="category.description"
                        ></textarea>
                    <span class="validation-error">{{ errors.first('description') }}</span>
                </div>

            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="submit" class="uk-button wpip-button" type="button">{{ modal.button }}</button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'budgetTemplateModal',
        data() {
            return {
                category: {
                    description: "",
                    name: ""
                },
                modal:
                    {
                        title: "Dodaj Kategorię",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (category) {
                this.category = category;
                if(!this.category){
                    this.category={}
                    this.modal =
                        {
                            title: "Dodaj kategorię główną",
                            button: "Dodaj",
                            blocked: false
                        }
                } else {
                    this.modal =
                        {
                            title: "Zmień kategorię główną",
                            button: "Zmień",
                            blocked: false
                        }
                }
                this.$validator.reset();
                this.modalDom.show();
            },
            submit: function(){
                let postData = this.category;
                delete(postData.budgetCategories);
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        this.modal.blocked = true;
                        if (this.category.id == null) {
                            postData.person = "/api/people/" + localStorage.getItem('person');
                            axiosInstance
                                .post(apiConfig.budgetMainCategoriesApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        postData = {
                                            budgetTemplate: "/api/budget_templates/" + vm.$route.params.templateId,
                                            budgetMainCategory: "/api/budget_main_categories/" + response.data.id,
                                            sequence: 0
                                        }
                                        axiosInstance
                                            .post(apiConfig.budgetTemplateMainCategoriesApiUrl, JSON.stringify(postData), {
                                                headers: vm.$data.headers
                                            })
                                            .then(async function (response) {
                                                if (response.status === 201) {
                                                    vm.$emit('refresh');
                                                    vm.modalDom.hide();
                                                }
                                                vm.modal.blocked = false;
                                            }).catch();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        } else {
                            axiosInstance
                                .patch(apiConfig.budgetMainCategoriesApiUrl + '/' + vm.category.id, JSON.stringify(postData), {
                                    headers: this.$data.headersLD
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        }
                    }
                });
            }
        },
        mounted() {
            if(this.modalDom==null) {
                this.modalDom = UIkit.modal('#budget-main-category-modal');
            }
        }
    }
</script>
