<template>
    <div class="uk-flex-top uk-modal-container uk-modal" container=".admin" id="budget-template-modal">
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-body">
                <h3>{{ modal.title }}</h3>
                <div class="uk-form-controls">
                    <label>
                        Nazwa
                    </label>
                        <input
                                type="text"
                                class="uk-input"
                                data-vv-as="nazwa"
                                name="name"
                                placeholder="Proszę podać nazwę szablonu"
                                v-model="template.name"
                                v-validate="'required|max:255'"
                        />
                    <span class="validation-error">{{ errors.first('name') }}</span>
                </div>
                <div class="uk-form-controls">
                    <label>
                        Opis
                    </label>
                        <textarea
                                class="uk-textarea"
                                data-vv-as="opis"
                                name="description"
                                placeholder="Proszę podać opis szablonu"
                                rows="5" v-model="template.description"
                        ></textarea>
                    <span class="validation-error">{{ errors.first('description') }}</span>
                </div>

            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" type="button">Anuluj</button>
                <button @click="submit" class="uk-button wpip-button" type="button">{{ modal.button }}</button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../mixin";
    import apiConfig from "../../apiConfig";
    import UIkit from "uikit";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'budgetTemplateModal',
        data() {
            return {
                template: {
                    description: "",
                    name: ""
                },
                modal:
                    {
                        title: "Szablon budżetu",
                        button: "Dodaj"
                    },

            }
        },
        mixins: [mixin],
        methods: {
            showModal: function (budgetTemplate) {
                this.template = budgetTemplate;
                if(!this.template){
                    this.template={}
                    this.modal =
                        {
                            title: "Dodaj szablon budżetu",
                            button: "Dodaj",
                            blocked: false
                        }
                } else {
                    this.modal =
                        {
                            title: "Zmień szablon budżetu",
                            button: "Zmień",
                            blocked: false
                        }
                }
                this.$validator.reset();
                this.modalDom.show();
            },
            submit: function(){
                let postData = {...this.template};
                let vm = this;
                vm.$validator.validate().then((result) => {
                    if (result) {
                        this.modal.blocked = true;
                        if (this.template.id == null) {
                            if(localStorage.getItem('person')!=='null') {
                                postData.person = "/api/people/" + localStorage.getItem('person');
                            } else {
                                postData.createdBy = "/api/users/" + localStorage.getItem('user');
                            }
                            axiosInstance
                                .post(apiConfig.budgetTemplatesApiUrl, JSON.stringify(postData), {
                                    headers: this.$data.headers
                                })
                                .then(async function (response) {
                                    if (response.status === 201) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        } else {
                            delete postData.budgetTemplateMainCategories;
                            axiosInstance
                                .patch(apiConfig.budgetTemplatesApiUrl + '/' + vm.template.id, JSON.stringify(postData), {
                                    headers: this.$data.headersLD
                                })
                                .then(async function (response) {
                                    if (response.status === 200) {
                                        vm.$emit('refresh');
                                        vm.modalDom.hide();
                                    }
                                    vm.modal.blocked = false;
                                }).catch();
                        }
                    }
                });
            }
        },
        mounted() {
            if(this.modalDom==null) {
                this.modalDom = UIkit.modal('#budget-template-modal');
            }
        }
    }
</script>
