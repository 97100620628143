<template>
    <div class="relative">
        <vk-grid class="uk-margin-bottom">
            <h3 class="uk-width-expand">Analiza QFD - Zależność parametrów</h3>
            <button
                    @click="back()"
                    class="uk-button wpip-button uk-width-auto"
            >Lista analiz QFD
            </button>
        </vk-grid>
        <vk-card>
            <div class="uk-overflow-auto">

                <table class="uk-table uk-table-divider">
                <tr>
                    <td></td>
                    <td v-bind:key="parameter.id" v-for="parameter in selectParameters()"><span
                            :uk-tooltip="parameter.name">{{limit(parameter, 25)}}</span></td>
                </tr>
                <tr v-bind:key="parameter.id" v-for="parameter in selectParameters()">
                    <td><span :uk-tooltip="parameter.name">{{limit(parameter, 25)}}</span></td>
                    <td v-bind:key="parameter.id+'_'+parameter2.id" v-for="parameter2 in selectParameters()"
                        v-bind:class="{'own-relation': parameter.id===parameter2.id}">{{
                        findCorrelation(parameter,parameter2) }}
                    </td>
                </tr>
                <tr class="sums-row">
                    <td class="uk-text-bold">Suma korelacji negatywnych</td>
                    <td v-bind:key="parameter.id" v-for="parameter in selectParameters()">{{ sumCorrelations(parameter).negative }}
                    </td>
                </tr>
                <tr>
                    <td class="uk-text-bold">Suma korelacji pozytywnych</td>
                    <td v-bind:key="parameter.id" v-for="parameter in selectParameters()">{{ sumCorrelations(parameter).positive }}
                    </td>
                </tr>

            </table>
            </div>
        </vk-card>
        <div class="uk-grid uk-child-width-1-2@m uk-margin-top" uk-grid>
            <div class="uk-text-left">

                <button class="uk-button wpip-button" v-on:click="previousStep">Parametry techniczne</button>
            </div>
            <div class="uk-text-right">
                <button class="uk-button wpip-button" v-on:click="nextStep">Ranga parametrów</button>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin from "../../components/mixin";
    import charLimiter from "../charLimiter";

    export default {
        name: 'qfdAnalysisCorrelationPreview',
        mixins: [mixin, charLimiter],
        data() {
            return {
                correlationsCount: [],
                sums: []
            }
        },
        props: {
            parameters: Array,
            properties: Array
        },
        methods: {
            async get() {

            },
            selectParameters: function () {
                let vm = this;
                let parameters = [];
                this.$props.parameters.forEach(function (parameter) {
                    if (vm.correlationsCount[parameter.id] > 0) {
                        parameters.push(parameter)
                    }
                });
                return parameters;
            },
            findCorrelation: function (parameter1, parameter2) {
                let result = 0;
                parameter1.qfdParameterCorrelations1.forEach(function (correlation) {
                    if (correlation.qfdParameter2.id === parameter2.id) {
                        result = correlation.correlation
                    }
                });
                parameter1.qfdParameter2Correlations.forEach(function (correlation) {
                    if (correlation.qfdParameter1.id === parameter2.id) {
                        result = correlation.correlation
                    }
                });
                if (result > 0) {
                    result = "+" + result;
                }
                return result
            },
            sumCorrelations: function (parameter) {
                let corr = 0;
                let sum = {
                    positive:0,
                    negative:0
                };
                let vm = this;
                this.selectParameters().forEach(function (parameter2) {
                    corr = vm.findCorrelation(parameter, parameter2);
                    if(corr>0){
                        sum.positive += parseInt(corr);
                    }
                    else{
                        sum.negative += parseInt(corr);
                    }
                });
                return sum;
            },
            negativeCorrelations: function () {
                let corr = 0;
                let negative = 0;
                let vm = this;
                this.selectParameters().forEach(function (parameter) {
                    vm.selectParameters().forEach(function (parameter2) {
                        corr = vm.findCorrelation(parameter, parameter2)
                        if(corr<0){
                            negative++;
                        }
                    });
                });
                return negative;
            },
            calculateSums: function () {
                let vm = this;
                this.sums[0] = 0;
                this.$props.parameters.forEach(function (parameter) {
                    vm.sums[parameter.id] = 0;
                    vm.correlationsCount[parameter.id] = 0;
                });
                this.$props.properties.forEach(function (property) {
                    property.parameters.forEach(function (parameter) {
                        if (parameter.weight > 0) {
                            vm.correlationsCount[parameter.id]++;
                        }
                        vm.sums[parameter.id] += property.weight * parameter.weight;
                        vm.sums[0] += property.weight * parameter.weight;
                    })
                });
            },
            getParameterValue(property, parameter) {
                let weight = null;
                property.parameters.forEach(function (propertyParameter) {
                    if (propertyParameter.id === parameter.id) {
                        weight = propertyParameter.weight;
                    }
                });
                return weight;
            },
            previousStep: function () {
                this.$emit('previousStep');
            },
            nextStep: function () {
                this.$emit('nextStep');
            },
            back: function () {
                this.$router.push({name: 'qfdAnalyses'})
            }

        },
        created() {
            this.calculateSums();
            this.get();
        }
    }
</script>
<style scoped>
    .own-relation {
        background-color: #eee;;
        color: #eee;;
    }

    .uk-card-iasm {
        background-color: rgb(191, 14, 1);
        color: #fff;
        padding: 20px;
    }

    .uk-card-iasm .uk-card-title {
        color: #fff;
        font-size: 36px;
        text-align: center;
    }

    .area{
        overflow-x: scroll;
    }

    .area td:first-child{
      border-collapse: collapse;
      background-clip: padding-box;
      position:sticky;
        left:0;
        border-top:1px solid rgb(229, 229, 229);
      background-color: white;
    }

    .area tr:first-child td:first-child{
        border-top:none;
    }

    .sums-row td{
        border-top: 2px solid black!important;
    }
</style>
