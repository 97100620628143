<template>
    <span>
        <span>
            <a @click="showLogs(datatable.item.id)" class="uk-icon-button" uk-tooltip="Pokaż logi" v-if="datatable.item.logsReady"><vk-icons-copy/></a>
        </span>
        <span>
            <a @click="downloadLogs(datatable.item.id)" class="uk-icon-button" uk-tooltip="Pobierz logi"><vk-icons-download/></a>
        </span>
        <span>
            <a @click="editMiddleware(datatable.item)" class="uk-icon-button" uk-tooltip="Edytuj middleware"><vk-icons-pencil/></a>
        </span>
        <span>
            <a @click="deleteMiddleware(datatable.item.id)" class="uk-icon-button" uk-tooltip="Usuń middleware"><vk-icons-trash/></a>
        </span>
        <remove ref="remove" v-on:refresh="$emit('refresh')"/>
    </span>
</template>

<script>

    import mixin from "../../components/mixin";
    import apiConfig from "../../apiConfig";
    import remove from "../ui/remove";
    import axiosInstance from "../../axiosInstance";

    export default {
        name: 'middlewaresActions',
        components: {
            remove
        },
        mixins: [mixin],
        props: {
            datatable: Object
        },
        methods: {
            showLogs(middlewareId){
                this.$router.push({name: 'middlewareLogs', params: {middlewareId: middlewareId}})
            },
            downloadLogs(middlewareId) {
                let vm = this;
                let postData = {
                    logsDate: new Date().toISOString(),
                    logsReady: false
                };
                axiosInstance
                    .patch(apiConfig.middlewaresApiUrl + '/' + middlewareId, JSON.stringify(postData), {
                        headers: this.$data.headersLD
                    })
                    .then(async function () {
                        await vm.$router.push({name: 'middlewareLogs', params: {middlewareId: middlewareId}})
                    }).catch();
            },
            editMiddleware(middleware) {
                this.$emit('edit', middleware)
            },
            deleteMiddleware(middlewareId) {
                this.$refs.remove.remove(apiConfig.middlewaresApiUrl + "/" + middlewareId, "middleware");
            },
        }
    }
</script>
