<template>
    <div class="uk-margin">
        <empty-list v-if="totalItems===0"/>
        <b-table v-else ref="table" hover borderedless
                 :fields="fields"
                 :columns="fields"
                 :items="data"
                 :per-page="this.$data.perPage"
                 :current-page="currentPage"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
no-sort-reset
:responsive="true">
            <template v-slot:head(actions)="data">
                <span class="uk-icon-link" uk-tooltip="Filtruj"><vk-icon class="hover" icon="settings" @click="$emit('toggleFilters')"/></span><span class="uk-badge uk-margin-small-left">{{$attrs.filterNum}}</span>
            </template>
            <template v-slot:cell(paymentData.datePayment)="data">
                <span v-if="data.item.paymentData.datePayment">{{ new Date(data.item.paymentData.datePayment).toLocaleDateString() }}</span>
            </template>
            <template v-slot:cell(paymentData.value)="data">
                {{ parseFloat(data.item.toPay).toFixed(2) }}
            </template>
            <template v-slot:cell(paymentData.status)="data">
                    <span class="uk-label uk-label-danger" v-if="new Date(data.item.paymentData.datePayment)<new Date() && data.item.paymentData.status===true &&data.item.paymentData.datePayment!==null">Zaległe</span>
                    <span class="uk-label uk-label-primary" v-else-if="data.item.paymentData.value>0 && data.item.paymentData.status===true">Wymagalne</span>
                    <span class="uk-label uk-label-success" v-else-if="data.item.paymentData.status===true">Zapłacone</span>
                    <span class="uk-label uk-label-success" v-else>Brak faktur</span>
            </template>
            <template v-slot:cell(actions)="data">
                <rental-list-actions :datatable="data" @showDetails="showDetails" />
            </template>

        </b-table>
        <b-pagination :total-rows="totalItems" :per-page="perPage" v-model="currentPage"
                      align="center" v-if="totalItems>perPage" size="sm" page-class="wpip-page" class="wpip-page"/>
        <invoice-modal ref="invoiceModal" @refresh="refreshData()"/>
        <rental-details-modal ref="rentalDetails" @edit="editInvoice" @addInvoice="addInvoice" @refresh="refresh()"/>
    </div>
</template>

<script>
    import mixin from "../../components/mixin";
    import charLimiter from "../charLimiter";
    import RentalListActions from "./rentalListActions";
    import InvoiceModal from "./invoiceModal";
    import axiosInstance from "../../axiosInstance";
    import apiConfig from "../../apiConfig";
    import RentalDetailsModal from "./rentalDetailsListModal";

    export default {
        name: "rentalList",
        components: {
            RentalDetailsModal,
            InvoiceModal,
            RentalListActions
        },
        data() {
            return {
                sortBy: 'name',
                sortDesc: false,
                data: [],
                currentPage: 1,
                totalItems: '',
                fields: [
                    {key: 'name', label: 'Najemca', sortable: true},
                    {key: 'paymentData.value', label: 'Należności (PLN)'},
                    {key: 'paymentData.datePayment', label: 'Data Wymagalności'},
                    {key: 'paymentData.status', label: 'Status'},
                    {key: 'actions', label: '', class: 'text-right'},
                ]
            };
        },
        mixins: [mixin, charLimiter],
        created() {
        },
        methods: {
            refresh: function(){
                this.get(this.$attrs.filters)
                this.$refs.table.refresh()
                this.$emit('refresh')
            },
            refreshData: function(){
                this.$refs.rentalDetails.refresh()
                this.refresh()
            },
        async get(filters={}) {
            let vm = this;
            let desc = 'desc';
            if (vm.sortDesc !== true) {
                desc = 'asc';
            }


            var key = "order[" + this.sortBy + "]";
            var params = filters;
            params[key] = desc;
            params['page'] = vm.currentPage;
            params['isRenter'] = true;

            let res = await axiosInstance
                .get(apiConfig.contractorsApiUrl,
                    {
                        params,
                        headers: this.$data.headers
                    });
            vm.data = res.data['member'];
            vm.data.forEach(function (renter) {
                renter.value = 100;
                renter.area = "50";
                renter.status = 1;
            });
            vm.totalItems = res.data['totalItems'];

            return vm.data;
        },
            addInvoice: function(data){
                this.$refs.invoiceModal.showModal(data)
            },
            editInvoice: function(data){
                this.$refs.invoiceModal.showEditModal(data)
            },
            showDetails: function(contractor){
                this.$refs.rentalDetails.showModal(contractor)
            }
        },
        mounted() {
            this.get();
        }
    };
</script>

<style scoped>
    .uk-accordion li {
        list-style-type: none;
    }

    .uk-accordion-title {
        color: black;
        font-size: 1.3em;
    }

    .uk-accordion {
        padding-left: 0;
    }
</style>
